import { useState } from 'react';

export function useDownload(initial: boolean) {
  const [isDownload, setDownload] = useState<boolean>(initial);

  return {
    isDownload,
    setDownload,
  };
}
