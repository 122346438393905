import { DateSettingsType } from 'app/modules/profile/slice/types';
import { NULL_DATE } from 'config/index';
import { formatDate } from './date';
import { OptionType } from '@rekosistem/web-components';
import { ALL_REGION } from 'app/pages/Dashboard/constants';

export const convertGetPlasticCreditDataParameter = (
  dateSettings: DateSettingsType,
) => {
  const data = {
    startDate:
      dateSettings.type === 'all-time'
        ? NULL_DATE
        : formatDate(dateSettings.startDate, 'yyyy-MM-dd'),
    endDate: formatDate(dateSettings.endDate, 'yyyy-MM-dd'),
    typeDate: dateSettings.type,
  };

  return data;
};

export const convertGetPlasticCreditRegionDataParameter = (
  dateSettings: DateSettingsType,
  params:
    | {
        sourceRegion: OptionType | undefined;
        destinationRegion: OptionType | undefined;
      }
    | undefined,
) => {
  const data = {
    startDate:
      dateSettings.type === 'all-time'
        ? NULL_DATE
        : formatDate(dateSettings.startDate, 'yyyy-MM-dd'),
    endDate: formatDate(dateSettings.endDate, 'yyyy-MM-dd'),
    typeDate: dateSettings.type,
    sourceRegion:
      params?.sourceRegion?.optionText !== ALL_REGION
        ? params?.sourceRegion?.optionText
        : undefined,
    destinationRegion:
      params?.destinationRegion?.optionText !== ALL_REGION
        ? params?.destinationRegion?.optionText
        : undefined,
  };

  return data;
};

export const formatPercentage = (input: string): string => {
  return input.replace(/%/g, '');
};
