import { ApiResponse } from 'apisauce';
import { GeneralDataResponse } from './api.types';

export enum ApiProblemKind {
  TimeOut = 'timeout',
  CannotConnect = 'cannot-connect',
  Server = 'server',
  Unauthorized = 'unauthorized',
  Forbidden = 'forbidden',
  NotFound = 'not-found',
  Rejected = 'rejected',
  Unknown = 'unknown',
  BadData = 'bad-data',
}

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | {
      kind: ApiProblemKind.TimeOut;
      temporary: true;
      response?: GeneralDataResponse;
    }
  /**
   * Cannot connect to the server for some reason.
   */
  | {
      kind: ApiProblemKind.CannotConnect;
      temporary: true;
      response?: GeneralDataResponse;
    }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: ApiProblemKind.Server; response?: GeneralDataResponse }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: ApiProblemKind.Unauthorized; response?: GeneralDataResponse }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: ApiProblemKind.Forbidden; response?: GeneralDataResponse }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: ApiProblemKind.NotFound; response?: GeneralDataResponse }
  /**
   * All other 4xx series errors.
   */
  | { kind: ApiProblemKind.Rejected; response?: GeneralDataResponse }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | {
      kind: ApiProblemKind.Unknown;
      temporary: true;
      response?: GeneralDataResponse;
    }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: ApiProblemKind.BadData; response?: GeneralDataResponse }
  | null;

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(
  response: ApiResponse<any>,
): GeneralApiProblem | null {
  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return {
        kind: ApiProblemKind.CannotConnect,
        temporary: true,
        response: response.data,
      };
    case 'NETWORK_ERROR':
      return {
        kind: ApiProblemKind.CannotConnect,
        temporary: true,
        response: response.data,
      };
    case 'TIMEOUT_ERROR':
      return {
        kind: ApiProblemKind.TimeOut,
        temporary: true,
        response: response.data,
      };
    case 'SERVER_ERROR':
      return { kind: ApiProblemKind.Server, response: response.data };
    case 'UNKNOWN_ERROR':
      return {
        kind: ApiProblemKind.Unknown,
        temporary: true,
        response: response.data,
      };
    case 'CLIENT_ERROR':
      switch (response.status) {
        case 400:
          return { kind: ApiProblemKind.Unauthorized, response: response.data };
        case 401:
          return { kind: ApiProblemKind.Unauthorized, response: response.data };
        case 403:
          return { kind: ApiProblemKind.Forbidden, response: response.data };
        case 404:
          return { kind: ApiProblemKind.NotFound, response: response.data };
        default:
          return { kind: ApiProblemKind.Rejected, response: response.data };
      }
    case 'CANCEL_ERROR':
      return null;
  }
  return null;
}
