import { useState } from 'react';
import {
  ACCESS_TOKEN_KEY,
  HEADER_USER_CODE_KEY,
  HEADER_USER_ID_KEY,
  REFRESH_TOKEN_KEY,
  ROLE_KEY,
  USER_DETAIL_KEY,
  USER_MP_DETAIL_KEY,
  WASTE_MANAGEMENT_TYPE_KEY,
} from '../api/api.types';
import { logger } from 'utils';

const log = logger().child({ module: 'UseToken' });

export function useToken() {
  const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  };

  const [token, setToken] = useState<string | null>(getAccessToken());
  const [refreshToken, setRefreshToken] = useState<string | null>(
    getRefreshToken(),
  );

  const saveToken = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    setToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const clearToken = () => {
    log.debug('clear token');
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_DETAIL_KEY);
    localStorage.removeItem(WASTE_MANAGEMENT_TYPE_KEY);
    localStorage.removeItem(ROLE_KEY);
    localStorage.removeItem(HEADER_USER_ID_KEY);
    localStorage.removeItem(HEADER_USER_CODE_KEY);
    localStorage.removeItem(USER_MP_DETAIL_KEY);
    setToken(null);
    setRefreshToken(null);
  };

  return {
    setToken: saveToken,
    clearToken,
    token,
    refreshToken,
  };
}
