import { DateSettingsType } from '../../modules/profile/slice/types';
import { formatDashboardDisplayDate } from '../../../utils/date';
import React from 'react';
import {
  BusinessOverviewType,
  DropInOverviewType,
  OverviewItemType,
  ReboxBrandboxOverviewType,
} from '../../../types/Dashboard';
import { WasteManagementType } from '../../../types/User';
import { convertWasteManagementType } from '../../../utils/dashboard-helper';
import {
  businessAvgWeight,
  businessRecoveryWeight,
  businessTotalPickup,
  businessTotalWeight,
  DASHBOARD_BUSINESS,
  DASHBOARD_DROP_IN,
  DATE_MONTH_TYPE,
  DATE_WEEK_TYPE,
  dropInAvgDropIn,
  dropInAvgUser,
  dropInAvgWeightPerDropIn,
  dropInRecoveryRate,
  dropInTotalUser,
  dropInTotalWasteDrop,
  dropInTotalWeight,
  overviewItemBusiness,
  overviewItemDropIn,
  overviewItemReboxBrandbox,
  reboxAvgUser,
  reboxAvgWastePerDrop,
  reboxTotalTransaction,
  reboxTotalUser,
  reboxTotalWasteDrop,
} from '../../../config/global.config';
import { OverviewItem } from './overviewItem';
import { PERCENTAGE_MULTIPLIER } from '../../pages/Dashboard/constants';

interface Props {
  dateSettings: DateSettingsType;
  selectedWasteManagement: WasteManagementType;
  businessOverview: BusinessOverviewType;
  reboxBrandboxOverview: ReboxBrandboxOverviewType;
  dropInOverview: DropInOverviewType;
}

const MONTH_TYPE: string = 'Month';
const WEEK_TYPE: string = 'Week';
const DAY_TYPE: string = 'Day';

export const Overview = (props: Props) => {
  const reboxBrandboxUnits = (item: OverviewItemType) => {
    if (item.key === reboxAvgUser.key) {
      return props.dateSettings.type === DATE_MONTH_TYPE
        ? item.units + MONTH_TYPE
        : props.dateSettings.type === DATE_WEEK_TYPE
        ? item.units + WEEK_TYPE
        : item.units + DAY_TYPE;
    } else {
      return item.units;
    }
  };

  const dropInUnits = (item: OverviewItemType) => {
    if (item.key === dropInAvgDropIn.key || item.key === dropInAvgUser.key) {
      return props.dateSettings.type === DATE_MONTH_TYPE
        ? item.units + MONTH_TYPE
        : props.dateSettings.type === DATE_WEEK_TYPE
        ? item.units + WEEK_TYPE
        : item.units + DAY_TYPE;
    } else {
      return item.units;
    }
  };

  const businessAmount = (item: OverviewItemType) => {
    if (item.key === businessTotalWeight.key) {
      return props.businessOverview?.totalWeight;
    } else if (item.key === businessTotalPickup.key) {
      return props.businessOverview?.totalPickup;
    } else if (item.key === businessAvgWeight.key) {
      return props.businessOverview?.averageWeight;
    } else if (item.key === businessRecoveryWeight.key) {
      return props.businessOverview?.recoveryRate * PERCENTAGE_MULTIPLIER;
    } else {
      return props.businessOverview?.totalCarbonSaving;
    }
  };

  const dropInAmount = (item: OverviewItemType) => {
    if (item.key === dropInTotalWeight.key) {
      return props.dropInOverview?.totalWeight;
    } else if (item.key === dropInTotalWasteDrop.key) {
      return props.dropInOverview?.totalWasteDrop;
    } else if (item.key === dropInAvgDropIn.key) {
      return props.dropInOverview?.averageDropin;
    } else if (item.key === dropInAvgWeightPerDropIn.key) {
      return props.dropInOverview?.averageWeightPerDropin;
    } else if (item.key === dropInTotalUser.key) {
      return props.dropInOverview?.totalUser;
    } else if (item.key === dropInAvgUser.key) {
      return props.dropInOverview?.averageUser;
    } else if (item.key === dropInRecoveryRate.key) {
      return props.dropInOverview?.recoveryRate * PERCENTAGE_MULTIPLIER;
    } else {
      return props.dropInOverview?.totalCarbonSaving;
    }
  };

  const reboxBrandboxAmount = (item: OverviewItemType) => {
    if (item.key === reboxTotalWasteDrop.key) {
      return props.reboxBrandboxOverview?.totalWasteDrop;
    } else if (item.key === reboxTotalTransaction.key) {
      return props.reboxBrandboxOverview?.totalTransaction;
    } else if (item.key === reboxTotalUser.key) {
      return props.reboxBrandboxOverview?.totalUser;
    } else if (item.key === reboxAvgWastePerDrop.key) {
      return props.reboxBrandboxOverview?.averageWastePerDrop;
    } else {
      return props.reboxBrandboxOverview?.averageUser;
    }
  };

  return (
    <>
      <div className="flex-shrink-0 flex flex-col w-full">
        <span className="font-semibold text-xl text-neutral-90 mb-4">
          Overview -
          <span className="font-normal text-xl text-neutral-90 ml-3">
            {formatDashboardDisplayDate(props.dateSettings)}
          </span>
        </span>
        <div className="flex flex-wrap w-full items-center justify-between">
          {convertWasteManagementType(props.selectedWasteManagement) ===
          DASHBOARD_BUSINESS
            ? overviewItemBusiness.map((item, index) => {
                return (
                  <OverviewItem
                    key={index.toString() + item.key}
                    name={item.name}
                    icon={item.icon}
                    amount={businessAmount(item)}
                    units={item.units}
                    type={convertWasteManagementType(
                      props.selectedWasteManagement,
                    )}
                    isPercentage={item.isPercentage}
                    isRoundUp={item.isRoundUp}
                    fixed={item.fixed}
                    tooltipId={item.tooltipId}
                    tooltipTitle={item.tooltipTitle}
                    tooltipContent={item.tooltipContent}
                    tooltipLink={item.tooltipLink}
                  />
                );
              })
            : convertWasteManagementType(props.selectedWasteManagement) ===
              DASHBOARD_DROP_IN
            ? overviewItemDropIn.map((item, index) => {
                return (
                  <OverviewItem
                    key={index.toString() + item.key}
                    name={item.name}
                    icon={item.icon}
                    amount={dropInAmount(item)}
                    units={dropInUnits(item)}
                    type={convertWasteManagementType(
                      props.selectedWasteManagement,
                    )}
                    isPercentage={item.isPercentage}
                    isRoundUp={item.isRoundUp}
                    fixed={item.fixed}
                    tooltipId={item.tooltipId}
                    tooltipTitle={item.tooltipTitle}
                    tooltipContent={item.tooltipContent}
                    tooltipLink={item.tooltipLink}
                  />
                );
              })
            : overviewItemReboxBrandbox.map((item, index) => {
                return (
                  <OverviewItem
                    key={index.toString() + item.key}
                    name={item.name}
                    icon={item.icon}
                    amount={reboxBrandboxAmount(item)}
                    units={reboxBrandboxUnits(item)}
                    type={convertWasteManagementType(
                      props.selectedWasteManagement,
                    )}
                    isPercentage={item.isPercentage}
                    isRoundUp={item.isRoundUp}
                    fixed={item.fixed}
                    tooltipId={item.tooltipId}
                    tooltipTitle={item.tooltipTitle}
                    tooltipContent={item.tooltipContent}
                    tooltipLink={item.tooltipLink}
                  />
                );
              })}
        </div>
      </div>
    </>
  );
};
