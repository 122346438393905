import * as React from 'react';
import { Constants } from '../../../constants';
import { images } from '../../../assets/images';
import useLogin from './hooks/useLogin';
import { LoginForm } from './components/login-form';
import { WELCOME } from './constants';
import { AuthLayout } from '../../components';
import { LoadingOverlay } from '@rekosistem/web-components';
import { DEFAULT_LOADING_TEXT } from '../../../config/global.config';

export function Login() {
  const {
    username,
    setUsername,
    password,
    setPassword,
    errors,
    usernameError,
    passError,
    loading,
    signIn,
    handleResetPasswordClick,
  } = useLogin();

  return (
    <div>
      <AuthLayout>
        <div className="flex flex-col mx-auto w-full h-full max-w-sm lg:w-96 justify-center">
          <img
            className="mx-auto mb-8 h-14"
            src={images.logoTagline}
            alt="Rekosistem Logo"
          />
          <h2 className="text-l font-bold text-dark text-center">
            {WELCOME + Constants.APP_NAME}
          </h2>
          <LoginForm
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            errors={errors}
            usernameError={usernameError}
            passError={passError}
            handleResetPasswordClick={handleResetPasswordClick}
            signIn={signIn}
            loading={loading}
          />
        </div>
      </AuthLayout>
      <LoadingOverlay isActive={loading} text={DEFAULT_LOADING_TEXT} />
    </div>
  );
}
