import React, { useState } from 'react';
import { Sankey, makeVisFlexible } from 'react-vis';
import { SankeyActiveLinkType, SankeyType } from '../../../types/Dashboard';
import {
  SANKEY_BLURRED_LINK_OPACITY,
  SANKEY_FOCUSED_LINK_OPACITY,
  SANKEY_HEIGHT,
  SANKEY_NODE_PADDING,
} from '../../../config/global.config';
import { SankeyHint } from './sankeyHint';
import { SANKEY_LEGEND } from '../../../constants';
import { SankeyLegend } from './sankeyLegend';
import { NotFound } from '../NotFound';
import {
  DATA_NOT_FOUND_DESC,
  DATA_NOT_FOUND_TITLE,
} from '../../pages/Dashboard/constants';

interface Props {
  id?: string;
  data: SankeyType;
}

export const SankeyChart = (props: Props) => {
  const [activeLink, setActiveLink] = useState<
    SankeyActiveLinkType | undefined
  >();
  const [out, setOut] = useState<boolean>(false);

  const FlexibleSankey = makeVisFlexible(Sankey);

  const links = (data: SankeyType) => {
    return data.links.map((d, i) => ({
      ...d,
      opacity:
        activeLink && i === activeLink.index
          ? SANKEY_FOCUSED_LINK_OPACITY
          : SANKEY_BLURRED_LINK_OPACITY,
    }));
  };

  const isEqual = (
    node: SankeyActiveLinkType,
    data: SankeyActiveLinkType | undefined,
  ) => {
    if (node.index !== data?.index) {
      setOut(false);
      setActiveLink(node);
    }
  };

  return (
    <div id={props.id} className="px-2 py-4">
      {!out ? <SankeyHint activeLink={activeLink} data={props.data} /> : null}
      {props.data.nodes.length && props.data.links.length ? (
        <div>
          <FlexibleSankey
            hideLabels={false}
            nodes={props.data.nodes}
            nodePadding={SANKEY_NODE_PADDING}
            links={links(props.data)}
            height={SANKEY_HEIGHT}
            hasVoronoi={false}
            onLinkMouseOver={node => isEqual(node, activeLink)}
            onLinkMouseOut={() => setOut(true)}
          />
          <SankeyLegend legend={SANKEY_LEGEND} />
        </div>
      ) : (
        <NotFound title={DATA_NOT_FOUND_TITLE} desc={DATA_NOT_FOUND_DESC} />
      )}
    </div>
  );
};
