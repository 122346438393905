import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { GlobalStyle } from 'styles';
import { useToken } from 'hooks';
import { useApiSlice } from 'api/slice';
import { useAuthSlice } from 'app/modules/profile';
import {
  ACCOUNT_MANAGER_PERMITTED_ROLE,
  DASHBOARD_PERMITTED_ROLE,
  MP_ROLE_ID,
  REPORT_PERMITTED_ROLE,
} from 'config';
import { HEADER_USER_ID_KEY, ROLE_KEY, USER_DETAIL_KEY } from 'api/api.types';
import { Login } from './pages/Auth';
import { Report } from './pages/Report/Loadable';
import { Dashboard } from './pages/Dashboard/Loadable';
import { Request, Reset } from './pages/ResetPassword/Loadable';
import { AccountManager, ShareAccess } from './pages/AccountManager/Loadable';
import { Profile } from './pages/Profile/Loadable';
import { NotFoundPage } from './pages/NotFoundPage';
import { BoughtInDashboard } from './pages/BoughtInDashboard/Loadable';
import { Leaderboard } from 'app/pages/Leaderboard/Loadable';
import { MPHome } from './pages/MPHome/Loadable';
import { UserDetailType } from 'types/User';
import { isEmptyString } from 'utils/check-string';

export function App() {
  const { i18n } = useTranslation();
  const { clearToken, token, refreshToken } = useToken();

  const { actionApi } = useApiSlice();
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();

  // Handle get role
  const getRole = () => {
    if (token) {
      const data: UserDetailType = localStorage?.getItem(USER_DETAIL_KEY)
        ? JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '')
        : '';
      const roleFromLogin = localStorage.getItem(ROLE_KEY);

      return roleFromLogin ?? data?.role;
    }
  };

  // Handle get Header User ID
  const getHeaderUserId = () => {
    const data: string = localStorage.getItem(HEADER_USER_ID_KEY) ?? '';
    return data;
  };

  useEffect(() => {
    dispatch(actionApi.loadToken());
    if (token) dispatch(actions.loadUserDetail());
  }, [token]);

  useEffect(() => {
    if (refreshToken) {
      dispatch(actions.setRefreshTokenData(refreshToken));
      dispatch(actions.loadRefreshToken());
    }
  }, [refreshToken]);

  // Handle route by role
  const RoleRoute = ({ roles, ...props }: any) => {
    const role = getRole();
    if (role === MP_ROLE_ID && isEmptyString(getHeaderUserId())) {
      return <Redirect to={process.env.PUBLIC_URL + '/mp-home'} />;
    } else
      return !roles.length || roles.includes(role) ? (
        <Route {...props} />
      ) : (
        <Redirect to="/" />
      );
  };

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Dashboard Rekosistem"
        defaultTitle="Dashboard Rekosistem"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Rekosistem" />
      </Helmet>

      <Switch>
        {/* navigation reverted to this file */}
        <Route
          exact
          path={process.env.PUBLIC_URL + '/login'}
          component={() => (token ? <Redirect to="/" /> : <Login />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/forgot-password'}
          component={() => (token ? <Redirect to="/" /> : <Request />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/reset-password/:token'}
          component={() => (token ? <Redirect to="/" /> : <Reset />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/logout'}
          component={() => {
            clearToken();
            window.location.replace('/login');
            return <></>;
          }}
        />

        {token ? (
          <Switch>
            <Route
              exact
              path={process.env.PUBLIC_URL + '/mp-home'}
              component={() => <MPHome />}
            />
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/'}
              component={() => <Dashboard title={'Overview'} />}
            />
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/company/:prefix'}
              component={() => <Dashboard title={'Overview'} />}
            />
            {/* Dashboard Route */}
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/dashboard'}
              component={() => <Dashboard title={'Overview'} />}
            />
            {/* Report Route */}
            <RoleRoute
              roles={REPORT_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/report'}
              component={() => <Report title={'Monthly Report'} />}
            />
            {/* Account Manager Route */}
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/account-manager'}
              component={() => <AccountManager title={'Account Manager'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/share-access'}
              component={() => <ShareAccess title={'Share Access'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/edit-share-access/:id'}
              component={() => <ShareAccess title={'Share Access'} />}
            />
            {/* Profile Route */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/profile'}
              component={() => <Profile title={'Profile'} />}
            />
            {/* Bought-In Dashboard */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/bought-in-dashboard'}
              component={() => (
                <BoughtInDashboard title={'Bought-In Dashboard'} />
              )}
            />
            {/* Leaderboard */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/leaderboard'}
              component={() => <Leaderboard />}
            />
            {/*Log Out Route*/}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/logout'}
              component={() => {
                clearToken();
                window.location.replace('/login');
                return <></>;
              }}
            />
            <Route path={'*'} component={() => <NotFoundPage />} />
          </Switch>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
