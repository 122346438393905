import { ImageProps, StackProps } from '@chakra-ui/react';
import {
  color,
  ETextType,
  ITextProps,
  radius,
  spacingScale,
} from '@rekosistem/web-components';
import { IButtonProps } from 'app/components';
import { images } from 'assets/images';

const stack = {
  container: {
    gap: spacingScale['3xL'],
    alignItems: 'flex-start',
    p: spacingScale['3xL'],
    width: '100%',
    borderRadius: radius.md,
    borderWidth: '1px',
    borderColor: color.border.secondary,
  },
  topContainer: {
    width: '100%',
    justifyContent: 'space-between',
  },
  topRightContainer: {
    p: spacingScale.lG,
    borderRadius: radius.xs,
    bgColor: color.beauBlue[50],
  },
  emptyContainer: {
    p: spacingScale['3xL'],
    gap: spacingScale['3xL'],
    justifyContent: 'center',
    alignItems: 'center',
    minH: '400px',
    width: '100%',
  },
  emptyTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacingScale['2xS'],
  },
  bottomContainer: {
    p: spacingScale.lG,
    borderRadius: radius.xs,
    bgColor: color.beauBlue[50],
    width: '100%',
  },
} as const satisfies Record<string, StackProps>;

const text = {
  title: {
    type: ETextType.XSHeadingBold,
    fontSize: '20px',
    lineHeight: '28px',
  },
  rightTitle: {
    type: ETextType.SParagraphRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
  emptyTitle: {
    text: 'No data available yet.',
    type: ETextType.MParagraphBold,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  emptySubTitle: {
    text: '-',
    type: ETextType.MParagraphRegular,
    fontSize: '16px',
    lineHeight: '24px',
    color: color.text.secondary,
    textAlign: 'center',
  },
  bottomText: {
    text: 'List of top cities that have contributed waste to be managed by Rekosistem.',
    type: ETextType.SParagraphRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
} as const satisfies Record<string, ITextProps>;

const button = {
  download: {
    className: 'bg-neutral-90 px-4 py-2',
    type: 'primary',
  },
} as const satisfies Record<string, IButtonProps>;

const image = {
  download: {
    className: 'h-5 ml-2',
    alt: 'download-icon',
    src: images.downloadIcon,
  },
  empty: {
    borderRadius: radius.full,
    width: '96px',
    height: '96px',
  },
} as const satisfies Record<string, ImageProps>;

export default { stack, text, button, image };
