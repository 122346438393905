import React from 'react';
import { images } from '../../../assets/images';
import { INotFoundProps } from './notFound.props';

export const NotFound = ({ title, desc }: INotFoundProps) => {
  return (
    <div className="flex flex-col justify-center items-center space-y-6 p-4 h-64 w-full">
      <img
        className="w-28 h-28 object-contain"
        src={images.searchDocumentIcon}
      />
      <div>
        <h2 className="font-bold text-lg text-neutral-90 text-center">
          {title}
        </h2>
        <h2 className="font-normal text-base text-neutral-60 text-center">
          {desc}
        </h2>
      </div>
    </div>
  );
};
