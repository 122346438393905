import React from 'react';
import { Spinner } from '../Spinner';

export const LoadingTable = () => {
  return (
    <div style={{ padding: '24px' }}>
      <Spinner />
      <div className={'text-center'}>Loading...</div>
    </div>
  );
};
