import html2canvas from 'html2canvas';

export const htmlToImage = async (id: string) => {
  const element = document.getElementById(id);
  const link = document.createElement('a');
  let canvas;
  if (element) {
    canvas = await html2canvas(element);
  }

  link.href = canvas.toDataURL('image/jpg');
  link.download = id + '.jpg';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
