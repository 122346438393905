import { useState } from 'react';

export function useModal() {
  const [isShowModal, setShowModal] = useState<boolean>(false);

  const showModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return {
    isShowModal,
    showModal,
    closeModal,
  };
}
