interface EnvConfig {
  isDevMode: boolean;
}

export const envConfig: EnvConfig = {
  isDevMode: process.env.REACT_APP_IS_DEV_MODE === 'true',
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT_DATA = 10;
export const DEFAULT_LIMIT_OPTION = 500;
export const DEFAULT_DELAY = 1000; // 1 second
export const DELAY_3S = 3000; // 1 second

export const DEVELOPMENT = 'DEVELOPMENT';
export const PRODUCTION = 'PRODUCTION';
