import { useState } from 'react';
import { DashboardTabType } from 'types';

export function useNavigationTab(initialTab: DashboardTabType) {
  const [activeTab, setActiveTab] = useState<DashboardTabType>(initialTab);
  return {
    activeTab,
    setActiveTab,
  };
}
