import React from 'react';
import { IDownloadType } from './download-type.props';

export const DownloadType = ({
  data,
  handleClick,
  checked = false,
}: IDownloadType) => {
  return (
    <div
      className="flex flex-row w-full border-[1px] p-3 border-neutral-30 rounded-md justify-between items-center"
      onClick={() => handleClick(data)}
    >
      <div className="flex">
        <img src={data.icon} className="object-contain h-5 w-5 mr-1" />
        <h3>{data.optionText}</h3>
      </div>
      <input
        className="bg-neutral-10 border-2 border-neutral-90 checked:border-2 checked:border-neutral-90 checked:bg-[#4D8D01]"
        type="radio"
        checked={checked}
        onClick={() => handleClick(data)}
      />
    </div>
  );
};
