/**
 *
 * VStack
 *
 */
import * as React from 'react';
import { classNames } from '../../../utils/classNames';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

export interface IButtonProps {
  className?: string;
  children?: React.ReactNode;
  type:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'with-icon'
    | 'dropdown'
    | 'side-bar'
    | 'transparent'
    | null;
  icon?: React.ReactNode;
  onClick?(): void;
  onDropdownClick?(data: boolean): void;
  disabled?: boolean;
  isDropdownOpen?: boolean;
}

export function Button(props: IButtonProps) {
  if (props.type === 'primary') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'danger') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'dropdown') {
    return (
      <button
        onClick={() => {
          if (props.onDropdownClick) {
            props.onDropdownClick(!props.isDropdownOpen);
          }
        }}
        className={props.className}
        disabled={props.disabled}
      >
        {props.isDropdownOpen === true ? (
          <ChevronUpIcon
            className="flex-shrink-0 h-4 w-4 text-neutral-10"
            aria-hidden="true"
          />
        ) : (
          <ChevronDownIcon
            className="flex-shrink-0 h-4 w-4 text-neutral-10"
            aria-hidden="true"
          />
        )}
      </button>
    );
  }

  if (props.type === 'side-bar') {
    return (
      <button
        className={classNames(
          'group flex items-center w-full text-neutral-10 hover:bg-bg-brand-primary px-3 py-2 text-base font-medium rounded-md',
          props.className,
        )}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'transparent') {
    return (
      <button
        className={classNames('text-base font-medium', props.className)}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <div className="flex flex-row items-center">
          {props.icon}
          {props.children}
        </div>
      </button>
    );
  }

  return (
    <div className={classNames('flex flex-row', props.className)}>
      {props.children}
    </div>
  );
}
