/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Profile = lazyLoad(
  () => import('./profile'),
  module => module.Profile,
);
