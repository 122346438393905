import * as React from 'react';
import { images } from '../../../assets/images';
import { LoginFooter } from '../../pages/Auth/components/login-footer';
import { ReactNode } from 'react';
import { classNames } from '../../../utils/classNames';

interface IAuthLayout {
  children?: ReactNode;
  thumbnail?: string;
}

export const AuthLayout = ({
  children,
  thumbnail = 'bg-bgLoginThumbnail',
}: IAuthLayout) => {
  return (
    <div className="flex h-screen p-4">
      <div
        className={classNames(
          'flex flex-col w-1/2 h-full rounded-lg bg-cover justify-end',
          thumbnail,
        )}
      >
        <div className="w-full h-1/4 p-8 backdrop-blur bg-white/20 rounded-b-lg">
          <img
            className="h-full object-contain"
            src={images.taglineWhite}
            alt="Rekosistem Tagline"
          />
        </div>
      </div>
      <div className="flex flex-col w-1/2 h-full">
        {children}
        <LoginFooter />
      </div>
    </div>
  );
};
