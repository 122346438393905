/**
 * Filters an array of objects to remove duplicates based on the `id` property.
 * The function preserves the order of items as they appear in the original array.
 *
 * @param {any[]} data - An array of objects, where each object should contain a unique `id` property.
 * @returns {any[]} An array of objects with duplicates removed based on the `id` property. The order of items is preserved.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 1, name: 'Alice' },
 *   { id: 3, name: 'Charlie' }
 * ];
 *
 * const filteredData = filterDuplicatesById(data);
 * console.log(filteredData);
 * // Output: [ { id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }, { id: 3, name: 'Charlie' } ]
 */
export function filterDuplicatesById(data: any[]): any[] {
  const seenIds = new Set();
  const uniqueItems: any[] = [];

  data.forEach(item => {
    if (!seenIds.has(item.id)) {
      seenIds.add(item.id);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}

/**
 * Filters an array of objects to remove duplicates based on a unique property specified by the `unique` parameter.
 * The function preserves the order of items as they appear in the original array.
 *
 * @param {any[]} data - An array of objects, where each object should contain the property specified by the `unique` parameter.
 * @param {string} unique - The name of the property used to determine uniqueness.
 * @returns {any[]} An array of objects with duplicates removed based on the specified unique property. The order of items is preserved.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 2, name: 'Bobby' },
 *   { id: 3, name: 'Charlie' }
 * ];
 *
 * const filteredData = filterDuplicatesByUnique(data, 'id');
 * console.log(filteredData);
 * // Output: [ { id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }, { id: 3, name: 'Charlie' } ]
 */
export function filterDuplicatesByUnique(data: any[], unique: string): any[] {
  const seen = new Set();
  const uniqueItems: any[] = [];

  data.forEach(item => {
    const uniqueValue = item[unique];
    if (!seen.has(uniqueValue)) {
      seen.add(uniqueValue);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}
