import React from 'react';
import { ISummaryCardProps } from './summary-card.props';
import { HStack, Image, VStack } from '@chakra-ui/react';
import {
  color,
  ETextType,
  NewText,
  radius,
  spacingScale,
} from '@rekosistem/web-components';
import { isEmptyString } from 'utils/check-string';
import { isUndefined } from 'lodash';

export const SummaryCard: React.FC<ISummaryCardProps> = props => {
  const {
    p = spacingScale.xL,
    borderWidth = '1px',
    borderColor = color.border.secondary,
    borderRadius = radius.md,
    title = '',
    subTitle = '',
    desc,
    count,
    icon,
    otherChildren = undefined,
    ...rest
  } = props;

  return (
    <VStack
      {...rest}
      p={p}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      alignItems={'flex-start'}
    >
      <HStack gap={spacingScale.xL}>
        <Image
          src={icon}
          alt="summary-card-icon"
          bgColor={color.beauBlue[100]}
          alignSelf={'flex-start'}
          w={'24px'}
          height={'24px'}
        />
        <VStack gap={spacingScale.xS} alignItems={'flex-start'}>
          <NewText
            text={title}
            type={ETextType.XSHeadingBold}
            fontSize={'20px'}
            lineHeight={'28px'}
          />
          {otherChildren ?? (
            <VStack gap={spacingScale.mD} alignItems={'flex-start'}>
              {!isUndefined(desc) || !isUndefined(count) ? (
                <NewText
                  text={`${desc ?? count ?? 0}`}
                  type={ETextType.LHeadingBold}
                  fontSize={'32px'}
                  lineHeight={'38.4px'}
                />
              ) : null}
              {!isEmptyString(subTitle) ? (
                <NewText
                  text={subTitle}
                  type={ETextType.MParagraphSemiBold}
                  fontSize={'16px'}
                  color={color.text.secondary}
                />
              ) : null}
            </VStack>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};
