import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from '../../../utils/classNames';

interface FilterOptions {
  name: string;
  value: string;
}

export enum SortOptions {
  ASC = 'asc',
  DESC = 'desc',
}

const sortOptionsDefault: FilterOptions[] = [
  { name: 'Ascending', value: SortOptions.ASC },
  { name: 'Descending', value: SortOptions.DESC },
];

interface Props {
  label: string;
  options?: FilterOptions[];
  onSelect?(data: any): void;
  value: null | any;
  className?: string;
}

export const SortFilter = ({
  label,
  options = sortOptionsDefault,
  onSelect = () => null,
  value = SortOptions.ASC,
  className = '',
}: Props) => {
  return (
    <div className={classNames('flex items-center justify-between', className)}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
            {label}
            <ChevronDownIcon
              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="py-1">
              {options.map((option, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        option.value === value
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => {
                        onSelect(option.value);
                      }}
                    >
                      {option.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
