import * as React from 'react';
import { Spinner } from '@rekosistem/web-components';
import { TextInput } from '../../../components/TextInput';
import {
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  PASS_LABEL,
  PASS_PLACEHOLDER,
} from '../constants';
import { FORGOT_PASSWORD } from '../../../../constants';
import { Button } from '../../../components/Button';
import { InputFootNote } from '../../ResetPassword/components/input-foot-note';
import { isEmptyString } from '../../../../utils/check-string';

interface Props {
  username: string;
  setUsername(data: string): void;
  password: string;
  setPassword(data: string): void;
  errors: string;
  usernameError: string;
  passError: string;
  signIn(): void;
  handleResetPasswordClick(): void;
  loading: boolean;
}

export const LoginForm = ({
  username,
  setUsername,
  password,
  setPassword,
  errors,
  usernameError,
  passError,
  loading,
  signIn,
  handleResetPasswordClick,
}: Props) => {
  return (
    <div>
      <div className="mt-6">
        {errors ? (
          <div className="flex w-full justify-center">
            <InputFootNote note={errors} isError />
          </div>
        ) : null}

        <div className="mb-6">
          <TextInput
            className="font-normal border-[#ECF3F7]"
            labelClassName="font-normal text-sm text-neutral-90"
            label={EMAIL_LABEL}
            type={'top-label'}
            placeholder={EMAIL_PLACEHOLDER}
            onChange={e => setUsername(e.target.value)}
            value={username}
            isError={!isEmptyString(usernameError)}
          />
          <InputFootNote note={usernameError} isError />
        </div>

        <div className="mb-6">
          <TextInput
            className="font-normal border-[#ECF3F7]"
            labelClassName="font-normal text-sm text-neutral-90"
            label={PASS_LABEL}
            type={'hide'}
            placeholder={PASS_PLACEHOLDER}
            onChange={e => setPassword(e.target.value)}
            value={password}
            isError={!isEmptyString(passError)}
          />
          <InputFootNote note={passError} isError />
        </div>

        <div className="flex flex-row w-full justify-end text-right my-6">
          <Button
            className="text-sm font-normal"
            type={'transparent'}
            onClick={handleResetPasswordClick}
          >
            {FORGOT_PASSWORD}
          </Button>
        </div>

        <div>
          <Button
            className={
              'w-full flex justify-center bg-neutral-100 !rounded-full disabled:bg-neutral-30 disabled:text-neutral-50'
            }
            type={'primary'}
            onClick={signIn}
            disabled={loading}
          >
            Log in
            {loading ? <Spinner size={'small'} className={'ml-2'} /> : null}
          </Button>
        </div>
      </div>
    </div>
  );
};
