import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.dashboard || initialState;

export const selectLoadingDashboard = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectWasteType = createSelector(
  [selectSlice],
  state => state.wasteType,
);

export const selectSelectedWasteType = createSelector(
  [selectSlice],
  state => state.selectedWasteType,
);

export const selectDashboardBusinessOverview = createSelector(
  [selectSlice],
  state => state.businessOverview,
);

export const selectDashboardBusinessWeightPerPickup = createSelector(
  [selectSlice],
  state => state.businessWeightPerPickup,
);

export const selectDashboardBusinessWeightPerType = createSelector(
  [selectSlice],
  state => state.businessWeightPerType,
);

export const selectFileType = createSelector(
  [selectSlice],
  state => state.fileType,
);

export const selectBusinessExcelWeightPerPickup = createSelector(
  [selectSlice],
  state => state.businessExcelWeightPerPickup,
);

export const selectBusinessExcelWeightPerType = createSelector(
  [selectSlice],
  state => state.businessExcelWeightPerType,
);

export const selectBusinessExcelWasteJourney = createSelector(
  [selectSlice],
  state => state.businessExcelWasteJourney,
);

export const selectBusinessCarbonFootprint = createSelector(
  [selectSlice],
  state => state.businessCarbonFootprint,
);
export const selectBusinessWasteJourney = createSelector(
  [selectSlice],
  state => state.businessWasteJourney,
);

// Rebox - Brandbox
export const selectDashboardReboxBrandboxOverview = createSelector(
  [selectSlice],
  state => state.reboxBrandboxOverview,
);
export const selectReboxBrandboxTotalWaste = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalWaste,
);

export const selectReboxBrandboxTotalTransaction = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalTransaction,
);

export const selectReboxBrandboxTotalUser = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalUser,
);

export const selectReboxBrandboxWeightPerType = createSelector(
  [selectSlice],
  state => state.reboxBrandboxWeightPerType,
);

export const selectReboxBrandboxTotalWasteExcel = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalWasteExcel,
);

export const selectReboxBrandboxWeightPerTypeExcel = createSelector(
  [selectSlice],
  state => state.reboxBrandboxWeightPerTypeExcel,
);

export const selectReboxBrandboxTotalTransactionExcel = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalTransactionExcel,
);

export const selectReboxBrandboxTotalUserExcel = createSelector(
  [selectSlice],
  state => state.reboxBrandboxTotalUserExcel,
);

// Drop In
export const selectDashboardDropInOverview = createSelector(
  [selectSlice],
  state => state.dropInOverview,
);

export const selectDropInWeight = createSelector(
  [selectSlice],
  state => state.dropInWeight,
);

export const selectDropInTotalDrop = createSelector(
  [selectSlice],
  state => state.dropInTotalDrop,
);

export const selectDropInTotalUser = createSelector(
  [selectSlice],
  state => state.dropInTotalUser,
);

export const selectDropInCarbonFootprint = createSelector(
  [selectSlice],
  state => state.dropInCarbonFootprint,
);

export const selectDropInWeightPerType = createSelector(
  [selectSlice],
  state => state.dropInWeightPerType,
);

export const selectDropInWasteJourney = createSelector(
  [selectSlice],
  state => state.dropInWasteJourney,
);

export const selectDropInWeightExcel = createSelector(
  [selectSlice],
  state => state.dropInWeightExcel,
);

export const selectDropInWasteExcel = createSelector(
  [selectSlice],
  state => state.dropInWasteExcel,
);

export const selectDropInUserExcel = createSelector(
  [selectSlice],
  state => state.dropInUserExcel,
);

export const selectDropInWeightPerTypeExcel = createSelector(
  [selectSlice],
  state => state.dropInWeightPerTypeExcel,
);

export const selectDropInWasteJourneyExcel = createSelector(
  [selectSlice],
  state => state.dropInWasteJourneyExcel,
);
