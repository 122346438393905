import { useEffect, useState } from 'react';
import { useAuthSlice } from '../../../modules/profile/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectErrors,
  selectProfileLoading,
  selectUserDetailSuccess,
  selectWasteManagement,
} from '../../../modules/profile/slice/selectors';
import { useHistory } from 'react-router-dom';
import { isEmail, isEmptyString } from '../../../../utils/check-string';
import {
  LOGIN_ERROR_EMPTY_EMAIL,
  LOGIN_ERROR_EMPTY_PASS,
  LOGIN_ERROR_NOT_EMAIL,
} from '../constants';

export default function useLogin() {
  const { actions } = useAuthSlice();

  const accessToken = useSelector(selectAccessToken);
  const errors = useSelector(selectErrors);
  const loading = useSelector(selectProfileLoading);
  const userDetailLoaded = useSelector(selectUserDetailSuccess);
  const wasteManagement = useSelector(selectWasteManagement);

  const history = useHistory();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [passError, setPassError] = useState<string>('');

  const signIn = async () => {
    if (!isEmptyString(username) && !isEmptyString(password)) {
      await dispatch(actions.setLoginData({ username, password }));
      await dispatch(actions.loadUserData());
    } else {
      if (isEmptyString(username)) setUsernameError(LOGIN_ERROR_EMPTY_EMAIL);
      if (isEmptyString(password)) setPassError(LOGIN_ERROR_EMPTY_PASS);
    }
  };

  const getUserDetail = async () => {
    await dispatch(actions.loadUserDetail());
  };

  // Handle on reset password click
  const handleResetPasswordClick = () => {
    history.push('/forgot-password');
  };

  useEffect(() => {
    if (!isEmptyString(username) && !isEmail(username)) {
      setUsernameError(LOGIN_ERROR_NOT_EMAIL);
    } else setUsernameError('');
    if (!isEmptyString(password)) setPassError('');
  }, [username, password]);

  useEffect(() => {
    if (accessToken) {
      getUserDetail();
    }
  }, [accessToken]);

  useEffect(() => {
    if (userDetailLoaded) {
      actions.setSelectedWasteManagement(wasteManagement[0]);
      history.go(0);
    }
  }, [history, userDetailLoaded]);

  return {
    username,
    setUsername,
    password,
    setPassword,
    errors,
    usernameError,
    passError,
    loading,
    signIn,
    handleResetPasswordClick,
  };
}
