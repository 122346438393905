import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectApi } from 'api/slice/selectors';
import { apiActions } from 'api/slice';
import { logger } from 'utils';
import {
  ChangePasswordType,
  EditAccessType,
  ResetPasswordType,
  ShareAccessType,
  PaginationParams,
  UserDetailType,
} from 'types';
import {
  ACCOUNT_LIST,
  ACCOUNT_MANAGER_OVERVIEW,
  ApiMethod,
  ApiResponseKind,
  BRANCH_LOCATION_LIST,
  CHANGE_PASSWORD,
  CHECK_PASSWORD,
  DELETE_ACCESS,
  EDIT_ACCESS_DETAIL,
  GET_ACCOUNT_DETAIL,
  LOGIN,
  REFRESH_TOKEN,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SHARE_ACCESS,
  USER_DETAIL,
  USER_MP_DETAIL_KEY,
} from 'api/api.types';
import { profileActions as actions } from './index';
import { LoginData } from './types';
import {
  selectAccountDetailId,
  selectChangePassData,
  selectCheckPassData,
  selectDeleteAccessId,
  selectEditAccessData,
  selectLoginData,
  selectProfilePaginationParams,
  selectRefreshToken,
  selectRequestResetPassword,
  selectResetPasswordData,
  selectShareAccessData,
} from './selectors';
import { MP_ROLE_ID } from 'config/index';

/**
 * Logger
 */
const log = logger().child({ module: 'ProfileSaga' });

export function* loginFlow() {
  const body: LoginData = yield select(selectLoginData);
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      LOGIN,
      false,
      undefined,
      undefined,
      {
        username: body.username,
        password: body.password,
      },
    );
    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      if (data?.status) {
        const token = {
          accessToken: data.data?.accessToken,
          refreshToken: data.data?.refreshToken,
        };
        yield put(actions.setUserData(data?.data));
        yield put(apiActions.setApiToken(token));
        yield put(apiActions.saveToken(token));
        yield put(apiActions.setRole(data?.data?.role));
      } else {
        yield put(actions.loginFailed(data));
      }
    } else yield put(actions.loginFailed(response?.response));
  } catch (err: any) {
    yield put(actions.loginFailed(err));
  }
}

export function* refreshTokenFlow() {
  const refreshToken: string = yield select(selectRefreshToken);
  const api = yield select(selectApi);

  try {
    yield put(actions.setStopRefresh(false));
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REFRESH_TOKEN,
      false,
      undefined,
      undefined,
      {
        refreshToken,
      },
    );

    if (response.kind === ApiResponseKind.OK) {
      yield put(actions.setStopRefresh(false));
      const token = {
        accessToken: response?.data.data.accessToken,
        refreshToken: response?.data.data.refreshToken,
      };
      yield put(apiActions.setApiToken(token));
      yield put(apiActions.saveToken(token));
    } else {
      yield put(actions.loadRefreshTokenFailed(response?.response));
      window.location.replace('/logout');
    }
  } catch (err: any) {
    yield put(actions.loadRefreshTokenFailed(err));
  }
}

function* getUserDetail() {
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      USER_DETAIL,
      false,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data: UserDetailType = response?.data?.data;

      if (data?.role === MP_ROLE_ID)
        localStorage.setItem(USER_MP_DETAIL_KEY, JSON.stringify(data));

      yield put(actions.userDetailLoaded(data));
      yield put(actions.userDetailSuccess());
    }
  } catch (err: any) {
    yield put(actions.userDetailError(err));
  }
}

function* postRequestResetPassword() {
  const api = yield select(selectApi);
  const requestResetPassword: string = yield select(selectRequestResetPassword);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REQUEST_RESET_PASSWORD,
      false,
      undefined,
      undefined,
      {
        email: requestResetPassword,
      },
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.requestResetPasswordSuccess(response.data.data));
    else yield put(actions.requestResetPasswordError(response.response));
  } catch (err: any) {
    yield put(actions.requestResetPasswordError(err));
  }
}

function* postResetPassword() {
  const api = yield select(selectApi);
  const data: ResetPasswordType = yield select(selectResetPasswordData);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      RESET_PASSWORD,
      false,
      undefined,
      undefined,
      data,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.resetPasswordSuccess());
    else yield put(actions.resetPasswordError(response.response));
  } catch (err: any) {
    yield put(actions.resetPasswordError(err));
  }
}

function* postChangePassword() {
  const api = yield select(selectApi);
  const data: ChangePasswordType = yield select(selectChangePassData);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      CHANGE_PASSWORD,
      false,
      undefined,
      undefined,
      data,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.changePassSuccess());
    else yield put(actions.changePassError(response.response));
  } catch (err: any) {
    yield put(actions.changePassError(err));
  }
}

function* postCheckPassword() {
  const api = yield select(selectApi);
  const data: string = yield select(selectCheckPassData);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      CHECK_PASSWORD,
      false,
      undefined,
      undefined,
      {
        currentPassword: data,
      },
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.checkPassSuccess());
    else yield put(actions.checkPassError(response.response));
  } catch (err: any) {
    yield put(actions.checkPassError(err));
  }
}

function* getAccountOverview() {
  const api = yield select(selectApi);
  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      ACCOUNT_MANAGER_OVERVIEW,
      false,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.accountManagerOverviewLoaded(response.data));
  } catch (err: any) {
    yield put(actions.accountManagerOverviewError(err));
  }
}

function* getAccountList() {
  const { page, limit, keyword }: PaginationParams = yield select(
    selectProfilePaginationParams,
  );
  const api = yield select(selectApi);

  log.debug(
    'get account list with page:',
    page,
    ' limit:',
    limit,
    ' keyword:',
    keyword,
  );

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      ACCOUNT_LIST,
      false,
      undefined,
      undefined,
      {
        page,
        limit,
        keyword,
      },
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.accountListLoaded(response?.data?.data));
  } catch (err: any) {
    yield put(actions.accountListError(err));
  }
}

function* postShareAccess() {
  const api = yield select(selectApi);
  const data: ShareAccessType = yield select(selectShareAccessData);
  log.debug('share access with', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      SHARE_ACCESS,
      false,
      undefined,
      undefined,
      data,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.shareAccessSuccess());
    else yield put(actions.shareAccessError(response.response));
  } catch (err: any) {
    yield put(actions.shareAccessError(err));
  }
}

function* getBranchLocationList() {
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      BRANCH_LOCATION_LIST,
      false,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.branchLocationLoaded(response.data));
  } catch (err: any) {
    yield put(actions.branchLocationError(err));
  }
}

function* submitDeleteAccess() {
  const api = yield select(selectApi);
  const id: number = yield select(selectDeleteAccessId);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.DELETE,
      DELETE_ACCESS + id,
      false,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.deleteAccessSuccess());
  } catch (err: any) {
    yield put(actions.branchLocationError(err));
  }
}

function* getAccountDetail() {
  const api = yield select(selectApi);
  const id: number = yield select(selectAccountDetailId);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      GET_ACCOUNT_DETAIL + id,
      false,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.accountDetailLoaded(response.data));
  } catch (err: any) {
    yield put(actions.accountDetailError(err));
  }
}

function* postEditAccess() {
  const api = yield select(selectApi);
  const data: EditAccessType = yield select(selectEditAccessData);

  log.debug('edit access with', data);
  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.PUT,
      EDIT_ACCESS_DETAIL,
      false,
      undefined,
      undefined,
      data,
    );
    if (response.kind === ApiResponseKind.OK)
      yield put(actions.editAccessSuccess());
    else yield put(actions.editAccessError(response.response));
  } catch (err: any) {
    yield put(actions.editAccessError(err));
  }
}

export function* authSaga() {
  yield takeLatest(actions.loadRefreshToken.type, refreshTokenFlow);
  yield takeLatest(actions.loadUserData.type, loginFlow);
  yield takeLatest(actions.loadUserDetail.type, getUserDetail);
  yield takeLatest(actions.requestResetPassword.type, postRequestResetPassword);
  yield takeLatest(actions.resetPassword.type, postResetPassword);
  yield takeLatest(actions.changePass.type, postChangePassword);
  yield takeLatest(actions.checkPass.type, postCheckPassword);
  yield takeLatest(actions.loadAccountManagerOverview.type, getAccountOverview);
  yield takeLatest(actions.loadAccountList.type, getAccountList);
  yield takeLatest(actions.shareAccess.type, postShareAccess);
  yield takeLatest(actions.loadBranchLocationList.type, getBranchLocationList);
  yield takeLatest(actions.deleteAccess.type, submitDeleteAccess);
  yield takeLatest(actions.loadAccountDetail.type, getAccountDetail);
  yield takeLatest(actions.editAccess.type, postEditAccess);
}
