import * as React from 'react';

interface Props {
  footerText: string;
  linkTo?: string;
  containerStyle?: string;
}

export const LoginFooterItem = ({
  footerText,
  linkTo = '',
  containerStyle = '',
}: Props) => {
  return (
    <div className={containerStyle}>
      <a href={linkTo}>
        <h2 className="text-sm text-neutral-80">{footerText}</h2>
      </a>
    </div>
  );
};
