import { PayloadAction } from '@reduxjs/toolkit';

import {
  GeneralDataResponse,
  USER_DETAIL_KEY,
  WASTE_MANAGEMENT_TYPE_KEY,
} from 'api/api.types';
import { convert, isEmptyList } from 'utils';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  User,
  UserDetailType,
  WasteManagementType,
  AccountDetailType,
  AccountListType,
  AccountManagerOverviewType,
  ChangePasswordType,
  EditAccessType,
  ResetPasswordType,
  ShareAccessType,
  Pagination,
  PaginationParams,
  SubAccountType,
} from 'types';
import {
  EPR_TYPE,
  FIRST_INDEX,
  PLASTIC_CREDIT_TYPE,
  SUB_ACCOUNT_ROLE_ID,
} from 'config';
import { paginationDefault, paginationParamsDefault } from '../../pagination';
import { authSaga } from './saga';
import { ProfileState, LoginData, DateSettingsType } from './types';

const defaultUserData: User = {
  accessUuid: '',
  userId: 0,
  username: '',
  name: 'testing',
  whatsapp: '',
  level: '',
  accessToken: '',
  refreshToken: '',
};

export const defaultUserDetail: UserDetailType = {
  id: 0,
  username: '',
  name: '',
  code: '',
  createdAt: '',
  prefix: '',
  role: SUB_ACCOUNT_ROLE_ID,
  isSubAccount: false,
  wasteManagementTypes: [],
  companyName: '',
};

export const defaultSelectedWasteManagement: WasteManagementType = {
  wasteManagementTypeId: [],
  wasteManagementTypeName: '',
  type: 0,
  subAccounts: [],
};

export const dateSettingsDefault: DateSettingsType = {
  type: 'all-time',
  startDate: new Date('01-01-2023'),
  endDate: new Date(),
};

export const defaultResetPassData: ResetPasswordType = {
  email: '',
  newPassword: '',
  token: '',
};

export const defaultChangePassData: ChangePasswordType = {
  newPassword: '',
  currentPassword: '',
};

export const defaultAccountManagerOverview: AccountManagerOverviewType = {
  totalHeadAccountManaged: 0,
  totalSubAccountManaged: 0,
  totalAccessShared: 0,
};

export const defaultShareAccessData: ShareAccessType = {
  name: '',
  role: '',
  username: '',
  subAccounts: [],
};

export const defaultAccountDetail: AccountDetailType = {
  name: '',
  role: '',
  code: '',
  subAccounts: [],
  isSubAccount: false,
  id: 0,
  username: '',
  prefix: '',
  createdAt: '',
};

export const defaultEditAccess: EditAccessType = {
  name: '',
  role: '',
  subAccounts: [],
  username: '',
  id: 0,
  deletedSubAccountId: [],
};

export const defaultSelectedSubAccount: SubAccountType = {
  id: 0,
  name: '',
  alias: '',
  droppointIds: [],
};

export const initialState: ProfileState = {
  userData: defaultUserData,
  userDetail: defaultUserDetail,
  userDetailSuccess: false,
  refreshToken: '',
  stopRefresh: false,
  loading: false,
  loginData: null,
  requestResetPassword: '',
  requestResetPasswordSuccess: false,
  requestResetPasswordFailed: false,
  resetPasswordData: defaultResetPassData,
  resetPasswordSuccess: false,
  resetPasswordFailed: false,
  changePasswordData: defaultChangePassData,
  changePasswordSuccess: false,
  changePasswordFailed: false,
  checkPassData: '',
  checkPass: undefined,
  accountManagerOverview: defaultAccountManagerOverview,
  accountList: [],
  paginationParams: paginationParamsDefault,
  pagination: paginationDefault,
  shareAccessData: defaultShareAccessData,
  shareAccessFailed: false,
  shareAccessSuccess: false,
  branchLocationList: [],
  deleteAccessId: 0,
  deleteAccessSuccess: false,
  deleteAccessFailed: false,
  accountDetailId: 0,
  accountDetail: defaultAccountDetail,
  editAccessData: defaultEditAccess,
  editAccessFailed: false,
  editAccessSuccess: false,
  errors: null,
  message: '',
  wasteManagement: [],
  wasteManagementSuccess: false,
  selectedWasteManagement: defaultSelectedWasteManagement,
  dateSettings: dateSettingsDefault,
  selectedSubAccount: defaultSelectedSubAccount,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginData(state, action: PayloadAction<LoginData>) {
      state.loginData = action.payload;
    },
    setRefreshTokenData(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    setStopRefresh(state, action: PayloadAction<boolean>) {
      state.stopRefresh = action.payload;
    },
    loadUserData(state) {
      state.loading = true;
      state.userData = defaultUserData;
    },
    loadRefreshToken(state) {
      state.loading = true;
    },
    setUserData(state, action: PayloadAction<User>) {
      state.loading = false;
      state.userData = action.payload;
    },
    loadRefreshTokenFailed(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    loginFailed(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.userData = defaultUserData;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },

    loadUserDetail(state) {
      state.loading = true;
      state.errors = null;
      state.userDetail = defaultUserDetail;
      state.userDetailSuccess = false;
      state.wasteManagementSuccess = false;
    },
    userDetailLoaded(state, action: PayloadAction<UserDetailType>) {
      state.loading = false;
      state.errors = null;

      // TODO : Hardcode
      const payload = action.payload;
      if (
        payload.wasteManagementTypes &&
        !isEmptyList(payload.wasteManagementTypes)
      ) {
        payload.wasteManagementTypes = [
          ...action.payload.wasteManagementTypes,
          {
            id: [44],
            type: PLASTIC_CREDIT_TYPE,
            name: 'Plastic Credit',
            locations: [],
          },
          {
            id: [55],
            type: EPR_TYPE,
            name: 'Extended Producer Responsibility (EPR)',
            locations: [],
          },
        ];

        state.userDetail = payload;
        const wasteManagement: WasteManagementType[] = convert(
          action.payload?.wasteManagementTypes,
        );
        state.wasteManagement = wasteManagement;

        localStorage.setItem(
          WASTE_MANAGEMENT_TYPE_KEY,
          JSON.stringify(wasteManagement),
        );
      }

      localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(action.payload));
      state.userDetailSuccess = true;

      state.wasteManagementSuccess = true;
    },
    userDetailSuccess(state) {
      state.userDetailSuccess = true;
    },
    userDetailError(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.userDetail = defaultUserDetail;
      state.userDetailSuccess = false;
      state.wasteManagementSuccess = false;
    },

    setSelectedWasteManagement(
      state,
      action: PayloadAction<WasteManagementType>,
    ) {
      state.selectedWasteManagement = action.payload;
      state.selectedSubAccount = !isEmptyList(action.payload.subAccounts)
        ? action.payload.subAccounts[FIRST_INDEX]
        : defaultSelectedSubAccount;
    },

    setDateSettings(state, action: PayloadAction<DateSettingsType>) {
      state.dateSettings = action.payload;
    },

    setSelectedSubAccount(state, action: PayloadAction<SubAccountType>) {
      state.selectedSubAccount = action.payload;
    },

    requestResetPassword(state, action: PayloadAction<string>) {
      state.loading = true;
      state.errors = null;
      state.message = '';
      state.requestResetPassword = action.payload;
      state.requestResetPasswordSuccess = false;
      state.requestResetPasswordSuccess = false;
    },
    requestResetPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.message = action.payload.message;
      state.requestResetPassword = '';
      state.requestResetPasswordSuccess = true;
    },
    requestResetPasswordError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.requestResetPassword = '';
      state.requestResetPasswordFailed = true;
    },

    resetPassword(state, action: PayloadAction<ResetPasswordType>) {
      state.loading = true;
      state.errors = null;
      state.message = '';
      state.resetPasswordData = action.payload;
      state.resetPasswordSuccess = false;
      state.resetPasswordFailed = false;
    },
    resetPasswordSuccess(state) {
      state.loading = false;
      state.resetPasswordData = defaultResetPassData;
      state.resetPasswordSuccess = true;
    },
    resetPasswordError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.resetPasswordData = defaultResetPassData;
      state.resetPasswordFailed = true;
    },

    changePass(state, action: PayloadAction<ChangePasswordType>) {
      state.loading = true;
      state.errors = null;
      state.message = '';
      state.changePasswordSuccess = false;
      state.changePasswordFailed = false;
      state.changePasswordData = action.payload;
    },
    changePassSuccess(state) {
      state.loading = false;
      state.changePasswordData = defaultChangePassData;
      state.changePasswordSuccess = true;
    },
    changePassError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.changePasswordData = defaultChangePassData;
      state.changePasswordFailed = true;
    },
    changePassReset(state) {
      state.loading = false;
      state.errors = null;
      state.message = '';
      state.changePasswordData = defaultChangePassData;
      state.changePasswordSuccess = false;
      state.changePasswordFailed = false;
    },

    setCheckPass(state, action: PayloadAction<boolean | undefined>) {
      state.errors = null;
      state.message = '';
      state.checkPassData = '';
      state.checkPass = action.payload;
    },
    checkPass(state, action: PayloadAction<string>) {
      state.errors = null;
      state.message = '';
      state.checkPassData = action.payload;
      state.checkPass = undefined;
    },
    checkPassSuccess(state) {
      state.checkPassData = '';
      state.checkPass = true;
    },
    checkPassError(state, action: PayloadAction<any>) {
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.checkPassData = '';
      state.checkPass = false;
    },

    // Account Manager
    loadAccountManagerOverview(state) {
      state.loading = true;
      state.errors = null;
      state.accountManagerOverview = defaultAccountManagerOverview;
    },
    accountManagerOverviewLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.accountManagerOverview = action.payload.data;
    },
    accountManagerOverviewError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.accountManagerOverview = defaultAccountManagerOverview;
    },

    // Account List
    setPaginationParams(state, action: PayloadAction<PaginationParams>) {
      state.paginationParams = action.payload;
    },
    setAccountList(state, action: PayloadAction<AccountListType[]>) {
      state.accountList = action.payload;
    },
    loadAccountList(state) {
      state.loading = true;
      state.errors = null;
      state.accountList = [];
    },
    accountListLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: AccountListType[];
      }>,
    ) {
      state.loading = false;
      state.errors = null;
      state.pagination = action.payload.pagination;
      state.accountList = action.payload.data;
    },
    accountListError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.accountList = [];
    },

    shareAccess(state, action: PayloadAction<ShareAccessType>) {
      state.loading = true;
      state.errors = null;
      state.message = '';
      state.shareAccessData = action.payload;
      state.shareAccessSuccess = false;
      state.shareAccessFailed = false;
    },
    shareAccessSuccess(state) {
      state.loading = false;
      state.shareAccessData = defaultShareAccessData;
      state.shareAccessSuccess = true;
    },
    shareAccessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.shareAccessData = defaultShareAccessData;
      state.shareAccessFailed = true;
    },
    shareAccessReset(state) {
      state.loading = false;
      state.errors = null;
      state.message = '';
      state.shareAccessData = defaultShareAccessData;
      state.shareAccessSuccess = false;
      state.shareAccessFailed = false;
    },

    loadBranchLocationList(state) {
      state.loading = true;
      state.errors = null;
      state.branchLocationList = [];
    },
    branchLocationLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.branchLocationList = action.payload.data;
    },
    branchLocationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.branchLocationList = [];
    },

    deleteAccess(state, action: PayloadAction<number>) {
      state.loading = true;
      state.errors = null;
      state.deleteAccessId = action.payload;
    },
    deleteAccessSuccess(state) {
      state.loading = false;
      state.errors = null;
      state.deleteAccessSuccess = true;
    },
    deleteAccessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.message = action.payload.message;
      state.deleteAccessFailed = true;
    },
    deleteAccessReset(state) {
      state.loading = false;
      state.errors = null;
      state.message = '';
      state.deleteAccessSuccess = false;
      state.deleteAccessFailed = false;
    },

    loadAccountDetail(state, action: PayloadAction<number>) {
      state.loading = true;
      state.errors = null;
      state.accountDetailId = action.payload;
      state.accountDetail = defaultAccountDetail;
    },
    accountDetailLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.accountDetail = action.payload.data;
      state.accountDetailId = 0;
    },
    accountDetailError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.accountDetail = defaultAccountDetail;
      state.accountDetailId = 0;
    },

    editAccess(state, action: PayloadAction<EditAccessType>) {
      state.loading = true;
      state.errors = null;
      state.message = '';
      state.editAccessData = action.payload;
      state.editAccessSuccess = false;
      state.editAccessFailed = false;
    },
    editAccessSuccess(state) {
      state.loading = false;
      state.editAccessData = defaultEditAccess;
      state.editAccessSuccess = true;
    },
    editAccessError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
      state.editAccessData = defaultEditAccess;
      state.editAccessFailed = true;
    },
    editAccessReset(state) {
      state.loading = false;
      state.errors = null;
      state.message = '';
      state.editAccessData = defaultEditAccess;
      state.editAccessFailed = false;
      state.editAccessSuccess = false;
    },
  },
});

export const { actions: profileActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
