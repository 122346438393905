import { ButtonProps, StackProps } from '@chakra-ui/react';
import {
  color,
  ETextType,
  IInputProps,
  ITextProps,
  spacingScale,
} from '@rekosistem/web-components';

export const buttonStyles = {
  default: {
    padding: '8px',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: color.neutral50,
    backgroundColor: color.neutral10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  go: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '44px',
    color: color.neutral90,
    _active: {
      color: color.text.tertiary,
    },
    variant: 'link',
  },
} as const satisfies Record<string, ButtonProps>;

export const containerStyles = {
  container: {
    justifyContent: 'end',
    gap: '16px',
    mt: spacingScale['2xL'],
  },
} as const satisfies Record<string, StackProps>;

export const textStyles = {
  totalData: {
    type: ETextType.MHeadingSemiBold,
    fontSize: '16px',
    lineHeight: '44px',
  },
  page: {
    type: ETextType.MHeadingSemiBold,
    fontSize: '16px',
    lineHeight: '44px',
  },
} as const satisfies Record<string, ITextProps>;

export const inputStyles = {
  inputPage: {
    inputMode: 'numeric',
    min: '1',
    width: '80px',
    style: { borderColor: color.neutral50 },
  },
} as const satisfies Record<string, IInputProps>;
