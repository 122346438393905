import React from 'react';

interface Props {
  legend: string[];
}

export const SankeyLegend = (props: Props) => {
  return (
    <div className="mt-2 flex flex-row w-full justify-between">
      {props.legend.map((item, index) => {
        return (
          <span key={index} className="font-semibold text-sm text-neutral-90">
            {item}
          </span>
        );
      })}
    </div>
  );
};
