import * as React from 'react';
import { COMPANY_WEB, CONTACT } from '../../../../config/global.config';
import { LoginFooterItem } from './login-footer-item';

export const WEB_FOOTER: string = 'rekosistem.com';
export const CONTACT_FOOTER: string = 'contact us';

export const LoginFooter = () => {
  const copyright = () => {
    return `© ${new Date().getFullYear()}`;
  };

  return (
    <div className="flex flex-row justify-center">
      <LoginFooterItem footerText={WEB_FOOTER} linkTo={COMPANY_WEB} />
      <LoginFooterItem
        footerText={CONTACT_FOOTER}
        linkTo={CONTACT}
        containerStyle={'mx-6'}
      />
      <LoginFooterItem footerText={copyright()} />
    </div>
  );
};
