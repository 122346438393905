import { StackProps } from '@chakra-ui/react';
import {
  color,
  ETextType,
  ITextProps,
  radius,
  spacingScale,
} from '@rekosistem/web-components';

const stack = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    py: spacingScale.mD,
  },
  circleContainer: {
    borderBottomWidth: '1px',
    borderBottomColor: color.border.tertiary,
  },
  circle: {
    bgColor: color.celurenBlue[500],
    borderRadius: radius.full,
    borderWidth: '1px',
    borderColor: color.border.secondary,
    width: '20px',
    height: '20px',
  },
  leftCircle: {
    gap: spacingScale.lG,
  },
  rightCircle: {
    gap: spacingScale.sM,
  },
  barContainer: {
    gap: spacingScale.xL,
  },
  spacingBar: {
    gap: spacingScale.mD,
  },
  bar: {
    bgColor: color.celurenBlue[500],
    borderRadius: radius.xs,
    width: '48px',
    height: '20px',
  },
} as const satisfies Record<string, StackProps>;

const text = {
  numbers: {
    type: ETextType.MParagraphMedium,
    fontSize: '16px',
    lineHeight: '24px',
    color: color.text.secondary,
  },
  label: {
    type: ETextType.MParagraphMedium,
    fontSize: '16px',
    lineHeight: '24px',
  },
  labelBar: {
    // width: '100%',
  },
  unitsValue: {
    type: ETextType.MParagraphBold,
    fontSize: '16px',
    lineHeight: '24px',
  },
  value: {
    type: ETextType.MParagraphBold,
    fontSize: '16px',
    lineHeight: '24px',
  },
  units: {
    type: ETextType.SParagraphMedium,
    fontSize: '14px',
    lineHeight: '22px',
    color: color.text.tertiary,
  },
  percentage: {
    type: ETextType.SParagraphSemiBold,
    fontSize: '14px',
    lineHeight: '22px',
    color: color.text.tertiary,
  },
} as const satisfies Record<string, ITextProps>;

export default { stack, text };
