/**
 * mainNavigator.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';

// Use consistent styling
import 'sanitize.css/sanitize.css';

import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

import reportWebVitals from 'reportWebVitals';

// Init Css
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-crop/dist/ReactCrop.css';

// Initialize languages
import './locales/i18n';
import { ApiProvider } from './api/context.api';

// Sentry
import * as Sentry from '@sentry/react';
import { ChakraProvider } from '@chakra-ui/react';
import { UITheme } from 'styles/theme/themes';

const store = configureAppStore();

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, //lower the value in production
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <ThemeProvider>
        <HelmetProvider>
          <React.StrictMode>
            <ChakraProvider theme={UITheme}>
              <App />
            </ChakraProvider>
          </React.StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </ApiProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
