import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';
import { ApiConfig, DEFAULT_API_CONFIG, UCO_API_CONFIG } from './api-config';
import { logger } from 'utils/logger';
import * as Types from './api.types';
import { ApiMethod, ApiResponseKind, MULTIPART_HEADER } from './api.types';
import { responseMonitor } from './api-monitor';
import { ResponseType } from 'axios';
import { MP_ROLE_ID } from 'config/global.config';
import { isEmptyList } from 'utils/list';

/**
 * Logger
 */
const log = logger().child({ module: 'MainApi' });

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  // @ts-ignore
  apisauce: ApisauceInstance;
  // @ts-ignore
  apisauceUco: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;
  configUco: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor() {
    this.config = DEFAULT_API_CONFIG;
    this.configUco = UCO_API_CONFIG;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    log.info('Setup Api');
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
    this.apisauce.addMonitor(responseMonitor);
  }

  setupUco() {
    log.info('Setup Api Uco');
    // construct the apisauce UCO instance
    this.apisauceUco = create({
      baseURL: this.configUco.url,
      timeout: this.configUco.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
    this.apisauceUco.addMonitor(responseMonitor);
  }

  setToken(token: string) {
    log.debug('TOKEN set 🚀 : ' + token);
    this.apisauce.setHeader('Authorization', `Bearer ${token}`);
    this.apisauceUco.setHeader('Authorization', `Bearer ${token}`);
  }

  removeToken() {
    log.info('TOKEN removed 🔥');
    this.apisauce.deleteHeader('Authorization');
    this.apisauceUco.deleteHeader('Authorization');
  }

  // General API Call
  async generalApiCall(
    method: ApiMethod,
    url: string,
    isMultipart: boolean,
    responseType?: ResponseType,
    timeout?: number,
    ...args
  ): Promise<Types.GeneralApiResult> {
    // make the api call
    let response: ApiResponse<any>;

    // For MP Access
    const isMP = localStorage.getItem(Types.ROLE_KEY)?.includes(MP_ROLE_ID);
    const userHeader = {
      'HEADER-USER-ID': localStorage.getItem(Types.HEADER_USER_ID_KEY) ?? '',
      'HEADER-USER-CODE':
        localStorage.getItem(Types.HEADER_USER_CODE_KEY) ?? '',
    };

    const config = {
      responseType: responseType,
      timeout: timeout,
      headers: isMultipart
        ? isMP
          ? { ...MULTIPART_HEADER, ...userHeader }
          : MULTIPART_HEADER
        : isMP
        ? userHeader
        : undefined,
    };

    const body = !isEmptyList(args) ? args[0] : undefined;

    // check api call metho
    if (method === ApiMethod.GET)
      response = await this.apisauce.get(url, body, config);
    else if (method === ApiMethod.POST)
      response = await this.apisauce.post(url, body, config);
    else if (method === ApiMethod.PUT)
      response = await this.apisauce.put(url, body, config);
    else response = await this.apisauce.delete(url, body, config);

    // return if there is a problem in api call
    if (!response.ok) return getGeneralApiProblem?.(response);

    // return if all ok
    return { kind: ApiResponseKind.OK, data: response.data };
  }
}
