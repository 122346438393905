import React from 'react';
import { SankeyActiveLinkType, SankeyType } from '../../../types/Dashboard';
import { kilogramUnits } from '../../../constants';

interface Props {
  id?: string;
  activeLink: SankeyActiveLinkType | undefined;
  data: SankeyType;
}

export const SankeyHint = (props: Props) => {
  const { nodes } = props.data;

  const hintText = () => {
    return `${
      props?.activeLink &&
      `${nodes[props?.activeLink.source.index].name} -> ${
        nodes[props?.activeLink.target.index].name
      }: ${props.activeLink?.value} ${kilogramUnits}`
    }`;
  };

  return (
    <>
      {props.activeLink ? (
        <div className="flex justify-center sticky top-20">
          <span className="bg-neutral-90 p-4 text-neutral-10 rounded-lg">
            {hintText()}
          </span>
        </div>
      ) : null}
    </>
  );
};
