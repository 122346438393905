import React from 'react';
import { classNames } from '../../../../utils/classNames';
import { images } from '../../../../assets/images';

interface Props {
  note: string;
  isError: boolean;
  showIcon?: boolean;
}

export const InputFootNote = ({ note, isError, showIcon = false }: Props) => {
  return (
    <div className="flex flex-row mt-1 items-center">
      {showIcon ? (
        <img
          className="mr-1 h-4"
          src={isError ? images.checkIconRed : images.checkIconGreen}
          alt="Rekosistem Logo"
        />
      ) : null}
      <span
        className={classNames(
          'text-sm font-normal ',
          isError ? 'text-red-500' : 'text-success',
        )}
      >
        {note}
      </span>
    </div>
  );
};
