import * as React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';

export interface OptionType {
  key: string;
  optionText: string;
  descText?: string;
  icon?: any;
  id?: number;
}

interface Props {
  buttonText: string;
  options: OptionType[];
  onClick(data: OptionType): void;
  dropdownTitle?: string;
  selected?: OptionType;
  className?: string;
  containerClassName?: string;
  dropdownClassName?: string;
  buttonTextClassName?: string;
  dropdownItemClassName?: string;
  disabled?: boolean;
}

export const FloatingDropdown = ({
  buttonText,
  options,
  onClick,
  dropdownTitle,
  selected,
  containerClassName = '',
  className = '',
  dropdownClassName,
  buttonTextClassName = '',
  dropdownItemClassName = '',
  disabled = false,
}: Props) => {
  return (
    <Menu
      as="div"
      className={classNames(
        'relative inline-block text-left',
        containerClassName,
      )}
    >
      <Menu.Button
        className={classNames(
          'inline-flex justify-between items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm min-w-[150px]',
          className,
        )}
        disabled={disabled}
      >
        <span
          className={classNames(buttonTextClassName, 'text-left overflow-auto')}
        >
          {buttonText}
        </span>
        {!disabled ? (
          <ChevronDownIcon
            className="flex-shrink-0 ml-1 w-4 h-4 text-neutral-90"
            aria-hidden="true"
          />
        ) : null}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-full overflow-auto px-2 py-2 absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col max-h-80 overflow-y-auto">
            <span className="font-normal text-left text-sm text-neutral-90 mb-4 px-2">
              {dropdownTitle}
            </span>
            {options.map((item, index) => {
              return (
                <Menu.Item key={index} onClick={() => onClick(item)}>
                  {
                    <a
                      className={classNames(
                        item.key === selected?.key
                          ? 'bg-[#ECF3F7] text-neutral-90'
                          : 'text-neutral-90',
                        'flex flex-row p-2 items-center justify-between',
                        dropdownClassName ?? 'text-base font-medium',
                      )}
                    >
                      <span
                        className={classNames(
                          dropdownItemClassName,
                          'text-left overflow-auto',
                        )}
                      >
                        {item.optionText}
                      </span>
                      {item.key === selected?.key ? (
                        <div>
                          <CheckIcon
                            className="h-5 w-5 text-chart"
                            aria-hidden="true"
                          />
                        </div>
                      ) : null}
                    </a>
                  }
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
