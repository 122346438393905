import {
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  StackProps,
} from '@chakra-ui/react';
import {
  color,
  EButtonSize,
  EButtonType,
  IButtonProps,
  radius,
  spacingScale,
} from '@rekosistem/web-components';

const content: ModalContentProps = {
  borderRadius: radius.md,
  gap: 0,
};

const header: ModalHeaderProps = {
  borderBottomWidth: '1px',
  borderBottomColor: color.border.secondary,
  px: spacingScale['3xL'],
  py: spacingScale.xL,
};

const stack = {
  header: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
} as const satisfies Record<string, StackProps>;

const footer: ModalHeaderProps = {
  borderTopWidth: '1px',
  borderTopColor: color.border.secondary,
  px: spacingScale['3xL'],
  py: spacingScale.xL,
  gap: spacingScale.xL,
  justifyContent: 'space-between',
  alignItems: 'center',
};

const body: ModalBodyProps = {
  p: spacingScale['3xL'],
  alignItems: 'flex-start',
};

const button = {
  negative: {
    size: EButtonSize.LG,
    type: EButtonType.Primary,
    text: 'batal',
  },
  positive: {
    size: EButtonSize.LG,
    type: EButtonType.Secondary,
    text: 'Ya',
  },
} as const satisfies Record<string, IButtonProps>;

export default { content, stack, header, body, button, footer };
