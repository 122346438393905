import React from 'react';
import { ISummaryCardProps } from './summary-card.props';
import { HStack, Image, VStack } from '@chakra-ui/react';
import {
  color,
  ETextType,
  NewText,
  radius,
  spacingScale,
} from '@rekosistem/web-components';

export const SummaryCard: React.FC<ISummaryCardProps> = props => {
  const {
    p = spacingScale.xL,
    borderWidth = '1px',
    borderColor = color.border.secondary,
    borderRadius = radius.md,
    title = '',
    subTitle = '',
    count = 0,
    icon,
    ...rest
  } = props;

  return (
    <VStack
      {...rest}
      p={p}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      alignItems={'flex-start'}
    >
      <HStack gap={spacingScale.xL}>
        <Image
          src={icon}
          alt="summary-card-icon"
          bgColor={color.beauBlue[100]}
          alignSelf={'flex-start'}
          w={'24px'}
          height={'24px'}
        />
        <VStack gap={spacingScale.xS} alignItems={'flex-start'}>
          <NewText
            text={title}
            type={ETextType.XSHeadingBold}
            fontSize={'20px'}
            lineHeight={'28px'}
          />
          <VStack gap={spacingScale.mD} alignItems={'flex-start'}>
            <NewText
              text={`${count}`}
              type={ETextType.LHeadingBold}
              fontSize={'32px'}
              lineHeight={'38.4px'}
            />
            <NewText
              text={subTitle}
              type={ETextType.MParagraphSemiBold}
              fontSize={'16px'}
              color={color.text.secondary}
            />
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
