import React, { useState } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { color, Input, NewText } from '@rekosistem/web-components';
import {
  buttonStyles,
  containerStyles,
  inputStyles,
  textStyles,
} from './custom-pagination.style';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ICustomPaginationProps } from './custom-pagination.props';

export const CustomPagination = (props: ICustomPaginationProps) => {
  const {
    rowCount,
    rowsPerPage,
    currentPage,
    totalRows,
    totalTitle,
    onChangePage,
  } = props;

  const [page, setPage] = useState<number>(currentPage);

  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers: React.ReactNode[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++)
        pageNumbers.push(renderPageButton(i));
    } else {
      // Show first 4 pages
      for (let i = 1; i <= 4; i++) pageNumbers.push(renderPageButton(i));

      // Show dots if currentPage >= 5
      if (currentPage >= 5 && currentPage <= totalPages)
        pageNumbers.push(renderDots('left-dots'));

      // Show current page and 2 pages before and after if within the middle range
      const startPage = Math.max(5, currentPage);
      const endPage = Math.min(currentPage + 3, totalPages);

      for (let i = startPage; i < endPage; i++)
        pageNumbers.push(renderPageButton(i));

      // Show dots if currentPage < totalPages - 4
      if (currentPage < totalPages - 3)
        pageNumbers.push(renderDots('right-dots'));

      // Always show the last page
      pageNumbers.push(renderPageButton(totalPages));
    }

    return pageNumbers;
  };

  const _handlerOnPressBack = () => {
    if (currentPage === 1) return;
    onChangePage(currentPage - 1, totalRows);
  };

  const _handlerOnPressNext = () => {
    if (currentPage === totalPages) return;
    onChangePage(currentPage + 1, totalRows);
  };

  const _handlerInputPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (isNaN(Number(e?.target.value)) || Number(e.target.value) > totalPages)
      return;

    setPage(Number(e.target.value));
  };

  const renderPageButton = (page: number) => (
    <Button
      {...buttonStyles.default}
      key={page}
      onClick={() => onChangePage(page, totalRows)}
      style={{
        backgroundColor:
          currentPage === page ? color.neutral90 : color.neutral10,
      }}
    >
      <NewText
        {...textStyles.page}
        text={`${page}`}
        color={currentPage === page ? color.white : color.neutral90}
      />
    </Button>
  );

  const renderDots = (key: string) => (
    <NewText
      {...textStyles.page}
      key={key}
      text="..."
      color={color.neutral90}
    />
  );

  return (
    <HStack {...containerStyles.container}>
      <NewText
        text={`Total ${totalRows} ${totalTitle}`}
        {...textStyles.totalData}
      />
      <Button
        {...buttonStyles.default}
        onClick={_handlerOnPressBack}
        style={{
          backgroundColor: currentPage === 1 ? color.neutral30 : color.white,
        }}
      >
        <ChevronLeftIcon
          className="h-6 w-6"
          aria-hidden="true"
          color={currentPage === 1 ? color.neutral50 : color.black}
        />
      </Button>
      {renderPageNumbers()}
      <Button
        {...buttonStyles.default}
        onClick={_handlerOnPressNext}
        style={{
          backgroundColor:
            currentPage === totalPages ? color.neutral30 : color.white,
        }}
      >
        <ChevronRightIcon
          className="h-6 w-6"
          aria-hidden="true"
          color={currentPage === totalPages ? color.neutral50 : color.black}
        />
      </Button>
      <Input
        {...inputStyles.inputPage}
        max={totalPages}
        value={page}
        onChange={_handlerInputPage}
      />
      <Button
        {...buttonStyles.go}
        onClick={() => onChangePage(page, totalRows)}
      >
        Go
      </Button>
    </HStack>
  );
};
