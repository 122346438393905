import { useState } from 'react';

export default function useLoading(initial: boolean) {
  const [loading, setLoading] = useState<boolean>(initial);

  return {
    loading,
    setLoading,
  };
}
