import { images } from 'assets/images';
import { IEprHeaderProps } from '../components/epr-header/epr-header.props';

export const DOWNLOAD: string = 'Download';
export const DOWNLOAD_MODAL_TITLE: string = 'Download Format';
export const DOWNLOAD_MODAL_POSITIVE_BUTTON: string = 'Download';
export const DOWNLOAD_MODAL_NEGATIVE_BUTTON: string = 'Cancel';
export const TOOLTIP_REFERENCE: string = 'Source:';
export const DATA_NOT_FOUND_TITLE: string = 'Currently, no data is available';
export const DATA_NOT_FOUND_DESC: string =
  'Data will display here once it becomes available.';
export const PERCENTAGE_MULTIPLIER: number = 100;

// TODO : hardcoded
export const EPR_HEADER_DUMMY_DATA: IEprHeaderProps = {
  title: 'P&G Conscious City',
  icon: images.eprLogo,
  backgroundImage: images.eprHeaderBackgound,
};

export const ALL_REGION: string = 'All Region';

export const EPR_HEADER_RIGHT_TEXT_DUMMY_DATA: string =
  'P&G Indonesia partners with Rekosistem to collect labeled products or packaging, which are then gathered and recycled to reduce the carbon footprint from the use or production of plastic materials.';
