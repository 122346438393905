import { DateSettingsType } from 'app/modules/profile/slice/types';
import { NULL_DATE } from 'config/index';
import { formatDate } from './date';

export const convertGetEPRDataParameter = (dateSettings: DateSettingsType) => {
  const data = {
    startDate:
      dateSettings.type === 'all-time'
        ? NULL_DATE
        : formatDate(dateSettings.startDate, 'yyyy-MM-dd'),
    endDate: formatDate(dateSettings.endDate, 'yyyy-MM-dd'),
    typeDate: dateSettings.type,
  };

  return data;
};