export const formatCurrency = (
  value: string | undefined | null,
  isRoundUp?: boolean,
  minFractionDigits?: number,
  maxFractionDigits?: number,
) => {
  if (!value) return 0;
  let n = Number(value);
  if (isRoundUp) n = Math.round(n);
  const formattedNumber: string = n.toLocaleString('en-US', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });

  return formattedNumber.trim();
};
