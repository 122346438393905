/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Request = lazyLoad(
  () => import('./request'),
  module => module.Request,
);

export const Reset = lazyLoad(
  () => import('./reset'),
  module => module.Reset,
);
