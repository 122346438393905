import React from 'react';
import { IProgressBarProps } from './progress-bar.props';
import { Box, Flex } from '@chakra-ui/react';
import { color as Colors, radius } from '@rekosistem/web-components';

export const ProgressBar: React.FC<IProgressBarProps> = props => {
  const {
    borderRadius = radius.xs,
    height = '1.25rem',
    value,
    max = 100,
    color = Colors.backgrounds.primaryBrand,
    ...rest
  } = props;
  const percentage = (value / max) * 100;

  return (
    <Box
      {...rest}
      width="100%"
      bg={Colors.beauBlue[100]}
      borderRadius={borderRadius}
      overflow="hidden"
    >
      <Flex
        borderRadius={borderRadius}
        height={height}
        width={`${percentage}%`}
        bg={color}
        transition="width 0.3s ease"
      ></Flex>
    </Box>
  );
};
