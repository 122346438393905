import React from 'react';
import { classNames } from '../../../utils/classNames';
import { DASHBOARD_DROP_IN } from '../../../config/global.config';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { isEmptyString } from '../../../utils/check-string';
import { ToolTip } from '../Tooltip';
import { formatCurrency } from '../../../utils/currency';
import { TOOLTIP_REFERENCE } from '../../pages/Dashboard/constants';

interface Props {
  name: string;
  icon: any;
  amount: number;
  units: string;
  type:
    | 'business'
    | 'drop-in'
    | 'rebox-brandbox'
    | 'account'
    | 'plastic-credit'
    | 'epr';
  fixed: boolean;
  isPercentage?: boolean;
  isRoundUp?: boolean;
  tooltipId?: string;
  tooltipTitle?: string;
  tooltipContent?: string;
  tooltipLink?: string;
  amountClassName?: string;
}

const INT_NUMBER: number = 0;
const DEC_2_NUMBER: number = 2;

const containerSize = (type: string) => {
  if (type === DASHBOARD_DROP_IN) return 'w-[24%] min-w-[24%]';
  else if (type === 'account') return 'w-[32%] min-w-[32%]';
  else return 'w-[19%] min-w-[19%] ';
};

export const OverviewItem = ({
  name,
  icon,
  amount,
  units,
  type,
  fixed,
  isPercentage = false,
  isRoundUp = false,
  tooltipId = '',
  tooltipTitle,
  tooltipContent,
  tooltipLink,
  amountClassName,
}: Props) => {
  const isFixed = () => {
    if (fixed) return INT_NUMBER;
    else return DEC_2_NUMBER;
  };

  return (
    <>
      <div
        className={classNames(
          'flex flex-col px-4 py-5 mb-4 border-[1px] border-neutral-50 rounded-xl',
          containerSize(type),
        )}
        data-tooltip-id={!isEmptyString(tooltipId) ? tooltipId : undefined}
      >
        <div className="flex-shrink-0 flex flex-row h-[44px] min-h-[44px]">
          <div className="flex w-full">
            <img className="h-5 mr-2" src={icon} alt="overview-icon" />
            <span className="font-semibold text-sm text-neutral-90">
              {name}
            </span>
          </div>
          {!isEmptyString(tooltipId) ? (
            <InformationCircleIcon
              className="mr-2 h-5 w-5"
              aria-hidden="true"
            />
          ) : null}
        </div>
        <span
          className={classNames(
            'my-1 font-medium text-3xl text-neutral-90',
            amountClassName,
          )}
        >
          {formatCurrency(amount.toFixed(isFixed()), isRoundUp, 0, 2)}
          {isPercentage ? '%' : ''}
        </span>
        <span className="font-normal text-base text-neutral-80">{units}</span>
      </div>
      <ToolTip
        id={tooltipId}
        containerClassName={'px-2 py-4 max-w-md'}
        titleClassName={'mb-4 text-base font-semibold'}
        title={tooltipTitle}
        desc={tooltipContent}
        descClassName={'whitespace-pre-line'}
        clickable
        place={'bottom'}
      >
        {tooltipLink ? (
          <div>
            <p className="font-normal mt-3 mb-1 text-sm text-neutral-10">
              {TOOLTIP_REFERENCE}
            </p>
            <p className="font-normal italic text-sm text-neutral-10">
              {tooltipLink}
            </p>
          </div>
        ) : null}
      </ToolTip>
    </>
  );
};
