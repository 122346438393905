import React from 'react';
import { Modal } from '@rekosistem/web-components';
import { Button } from 'app/components';
import {
  DOWNLOAD_MODAL_NEGATIVE_BUTTON,
  DOWNLOAD_MODAL_POSITIVE_BUTTON,
  DOWNLOAD_MODAL_TITLE,
} from './constants';
import { DownloadType } from './components';
import { IDownloadModalProps } from './download-modal.props';

export const DownloadModal = ({
  isShowModal,
  closeModal,
  downloadOption,
  handleDownload,
  setSelected,
  selected,
}: IDownloadModalProps) => {
  return (
    <Modal show={isShowModal} onClose={closeModal}>
      <div className="space-y-6">
        <h2 className="text-xl font-semibold">{DOWNLOAD_MODAL_TITLE}</h2>
        <div className="flex flex-row gap-2">
          {downloadOption.map(item => {
            return (
              <DownloadType
                key={item.key}
                data={item}
                handleClick={setSelected}
                checked={selected?.key === item.key}
              />
            );
          })}
        </div>
        <div className="flex flex-row gap-2 justify-end items-center">
          <Button
            type={'transparent'}
            className="!px-5 !rounded-full border-[1px] border-neutral-30"
            onClick={closeModal}
          >
            {DOWNLOAD_MODAL_NEGATIVE_BUTTON}
          </Button>
          <Button
            type={'primary'}
            className="!px-5 !rounded-full bg-neutral-90 disabled:bg-neutral-30 disabled:text-neutral-50"
            disabled={!selected}
            onClick={() => handleDownload(selected)}
          >
            {DOWNLOAD_MODAL_POSITIVE_BUTTON}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
