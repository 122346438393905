import {
  CloseButton,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { IConfirmationModalProps } from './confirmation-modal.props';
import {
  EButtonSize,
  EButtonType,
  ETextType,
  NewButton,
  NewText,
} from '@rekosistem/web-components';
import styles from './confirmation-modal.style';

export const ConfirmationModal: React.FC<IConfirmationModalProps> = props => {
  return (
    <Modal
      {...props}
      isOpen={props?.isVisible}
      isCentered={props?.isCentered ?? true}
    >
      <ModalOverlay />
      <ModalContent {...styles.content} {...props?.style}>
        <ModalHeader {...styles.header}>
          <HStack {...styles.stack.header}>
            <NewText
              type={props?.titleType ?? ETextType.XXLParagraphBold}
              text={props?.title}
            />
            <CloseButton size={'lg'} onClick={props?.onClose} />
          </HStack>
        </ModalHeader>
        <ModalBody {...styles.body}>
          <NewText
            type={props?.descType ?? ETextType.MParagraphMedium}
            text={props?.desc}
          />
        </ModalBody>
        <ModalFooter {...styles.footer} {...props?.footerStyle}>
          {props?.negativeText ? (
            <NewButton
              {...styles.button.negative}
              onClick={props?.onPressNegative ?? props?.onClose}
              type={props?.negativeButtonType ?? EButtonType.Primary}
              size={props?.negativeButtonSize ?? EButtonSize.LG}
              textType={props?.negativeTextType}
              text={props?.negativeText}
              width={'100%'}
            />
          ) : null}
          {props?.positiveText ? (
            <NewButton
              {...styles.button.positive}
              onClick={props?.onPressPositive}
              type={props?.positiveButtontype ?? EButtonType.Secondary}
              size={props?.positiveButtonSize ?? EButtonSize.LG}
              textType={props?.positiveTextType}
              text={props?.positiveText}
              width={'100%'}
            />
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
