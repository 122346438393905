// Handle password check
export const validatePassword = (pass: string) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return regex.test(pass);
};

export const checkCharacter = (pass: string, numOfChar: number) => {
  return pass.length >= numOfChar;
};

export const checkNumber = (pass: string) => {
  return /\d/.test(pass);
};

export const checkUppercase = (pass: string) => {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(pass);
};

// Handle validate confirmation password
export const validateConfPass = (pass: string, confPass: string) => {
  return pass === confPass;
};
