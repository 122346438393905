export const isEmptyString = (data: string | undefined) => {
  return data === '';
};

export const replaceChar = (
  data: string,
  char: string,
  replacement: string,
) => {
  return data.replace(char, replacement);
};

export const isEmail = (data: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data);
};
