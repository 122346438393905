import React from 'react';
import { CarbonFootprintType } from '../../../types/Dashboard';
import { images } from '../../../assets/images';
import { CarbonFootprintItem } from './carbonFootprintItem';
import {
  CARBON_FOOTPRINT_DECIMAL_NUM,
  carbonFootprint,
  cfCoalBurned,
  cfGasoline,
  cfTravelDistance,
  cfTreeSeed,
} from '../../../config/global.config';
import {
  carbonFootprintTitle,
  carbonSavings,
  co2Emission,
  co2EmissionEquals,
} from '../../../constants';

interface Props {
  id?: string;
  carbonSavings: number;
  treeSeed: number;
  travelDistance: number;
  coalBurned: number;
  gasoline: number;
  recycledWaste: number;
}

export const CarbonFootprint = (props: Props) => {
  const amount = (item: CarbonFootprintType) => {
    if (item.key === cfTreeSeed.key) {
      return props.treeSeed;
    } else if (item.key === cfTravelDistance.key) {
      return props.travelDistance;
    } else if (item.key === cfCoalBurned.key) {
      return props.coalBurned;
    } else if (item.key === cfGasoline.key) {
      return props.gasoline;
    } else {
      return props.recycledWaste;
    }
  };

  const isOr = (index: number) => {
    return index !== 0;
  };

  return (
    <div
      id={props.id}
      className="flex flex-col w-full px-4 py-4 border-[1px] border-neutral-50 rounded-lg"
    >
      <span className="font-semibold text-base text-neutral-90 mb-2">
        {carbonFootprintTitle}
      </span>
      <div className="flex flex-col px-4 py-4 border-[1px] border-neutral-50 rounded-lg mb-2 bg-bgCarbon6 bg-cover">
        <div className="flex flex-row items-center">
          <img
            className="h-5 mr-2"
            src={images.carbonIcon}
            alt="overview-icon"
          />
          <span className="font-semibold text-sm text-neutral-90">
            {carbonSavings}
          </span>
        </div>
        <span className="my-1 font-medium text-4xl text-neutral-90">
          {props.carbonSavings.toFixed(CARBON_FOOTPRINT_DECIMAL_NUM)}
        </span>
        <span className="font-normal text-base text-neutral-80">
          {co2Emission}
        </span>
      </div>
      <span className="font-semibold text-base text-neutral-90 mb-2">
        {co2EmissionEquals}
      </span>
      <div className="flex flex-wrap w-full items-center justify-between">
        {carbonFootprint.map((item, index) => {
          return (
            <CarbonFootprintItem
              key={index.toString() + item.key}
              isOr={isOr(index)}
              data={item}
              amount={amount(item)}
            />
          );
        })}
      </div>
    </div>
  );
};
