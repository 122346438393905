import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.auth || initialState;

export const selectLoginData = createSelector(
  [selectSlice],
  state => state.loginData,
);
export const selectAuthMessage = createSelector(
  [selectSlice],
  state => state.message,
);
export const selectAccessToken = createSelector(
  [selectSlice],
  state => state.userData.accessToken,
);
export const selectRefreshToken = createSelector(
  [selectSlice],
  state => state.refreshToken,
);

export const selectStopRefresh = createSelector(
  [selectSlice],
  state => state.stopRefresh,
);

export const selectErrors = createSelector(
  [selectSlice],
  state => state.errors,
);

export const selectUserDetail = createSelector(
  [selectSlice],
  state => state.userDetail,
);

export const selectUserDetailSuccess = createSelector(
  [selectSlice],
  state => state.userDetailSuccess,
);

export const selectProfileLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectWasteManagement = createSelector(
  [selectSlice],
  state => state.wasteManagement,
);

export const selectWasteManagementSuccess = createSelector(
  [selectSlice],
  state => state.wasteManagementSuccess,
);

export const selectWasteManagementSelected = createSelector(
  [selectSlice],
  state => state.selectedWasteManagement,
);

export const selectDateSettings = createSelector(
  [selectSlice],
  state => state.dateSettings,
);

export const selectSelectedSubAccount = createSelector(
  [selectSlice],
  state => state.selectedSubAccount,
);

export const selectRequestResetPassword = createSelector(
  [selectSlice],
  state => state.requestResetPassword,
);
export const selectRequestResetPasswordSuccess = createSelector(
  [selectSlice],
  state => state.requestResetPasswordSuccess,
);
export const selectRequestResetPasswordFailed = createSelector(
  [selectSlice],
  state => state.requestResetPasswordFailed,
);
export const selectResetPasswordData = createSelector(
  [selectSlice],
  state => state.resetPasswordData,
);
export const selectResetPasswordSuccess = createSelector(
  [selectSlice],
  state => state.resetPasswordSuccess,
);
export const selectResetPasswordFailed = createSelector(
  [selectSlice],
  state => state.resetPasswordFailed,
);
export const selectChangePassData = createSelector(
  [selectSlice],
  state => state.changePasswordData,
);
export const selectChangePassSuccess = createSelector(
  [selectSlice],
  state => state.changePasswordSuccess,
);
export const selectChangePassFailed = createSelector(
  [selectSlice],
  state => state.changePasswordFailed,
);
export const selectCheckPassData = createSelector(
  [selectSlice],
  state => state.checkPassData,
);
export const selectCheckPass = createSelector(
  [selectSlice],
  state => state.checkPass,
);
export const selectAccountManagerOverview = createSelector(
  [selectSlice],
  state => state.accountManagerOverview,
);
export const selectAccountList = createSelector(
  [selectSlice],
  state => state.accountList,
);
export const selectProfilePaginationParams = createSelector(
  [selectSlice],
  state => state.paginationParams,
);
export const selectProfilePagination = createSelector(
  [selectSlice],
  state => state.pagination,
);
export const selectShareAccessData = createSelector(
  [selectSlice],
  state => state.shareAccessData,
);
export const selectShareAccessSuccess = createSelector(
  [selectSlice],
  state => state.shareAccessSuccess,
);
export const selectShareAccessFailed = createSelector(
  [selectSlice],
  state => state.shareAccessFailed,
);
export const selectBranchLocationList = createSelector(
  [selectSlice],
  state => state.branchLocationList,
);
export const selectDeleteAccessId = createSelector(
  [selectSlice],
  state => state.deleteAccessId,
);
export const selectDeleteAccessSuccess = createSelector(
  [selectSlice],
  state => state.deleteAccessSuccess,
);
export const selectDeleteAccessFailed = createSelector(
  [selectSlice],
  state => state.deleteAccessFailed,
);
export const selectAccountDetailId = createSelector(
  [selectSlice],
  state => state.accountDetailId,
);
export const selectAccountDetail = createSelector(
  [selectSlice],
  state => state.accountDetail,
);
export const selectEditAccessData = createSelector(
  [selectSlice],
  state => state.editAccessData,
);
export const selectEditAccessSuccess = createSelector(
  [selectSlice],
  state => state.editAccessSuccess,
);
export const selectEditAccessFailed = createSelector(
  [selectSlice],
  state => state.editAccessFailed,
);
