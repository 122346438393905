export const images = {
  addUserIcon: require('./addUserIcon.png'),
  arrowsSort: require('./arrowsSort.png'),
  background: require('./background.png'),
  bgCarbon1: require('./bgCarbon1.png'),
  bgCarbon2: require('./bgCarbon2.png'),
  bgCarbon3: require('./bgCarbon3.png'),
  bgCarbon4: require('./bgCarbon4.png'),
  bgCarbon5: require('./bgCarbon5.png'),
  bgCarbon6: require('./bgCarbon6.png'),
  bgCarbonBlur1: require('./bgCarbonBlur1.png'),
  bgCarbonBlur2: require('./bgCarbonBlur2.png'),
  bgCarbonBlur3: require('./bgCarbonBlur3.png'),
  bgCarbonBlur4: require('./bgCarbonBlur4.png'),
  bgCarbonBlur5: require('./bgCarbonBlur5.png'),
  calendarIcon: require('./calendarIcon.png'),
  carbonIcon: require('./carbonIcon.png'),
  checkIcon: require('./checkIcon.png'),
  checkIconGreen: require('./checkIconGreen.png'),
  checkIconRed: require('./checkIconRed.png'),
  clipboardIcon: require('./clipboardIcon.png'),
  csvIcon: require('./csvIcon.png'),
  cycleIcon: require('./cycleIcon.png'),
  downloadIcon: require('./downloadIcon.png'),
  dropInIcon: require('./dropInIcon.png'),
  emailSentIcon: require('./emailSentIcon.png'),
  emptyLeaderboardIcon: require('./emptyLeaderboardIcon.png'),
  eprHeaderBackgound: require('./eprHeaderBackground.png'),
  eyeIcon: require('./eyeIcon.png'),
  loginThumbnail: require('./loginThumbnail.png'),
  logoRekoButton: require('./logoRekoButton.png'),
  logoTagline: require('./logoTagline.png'),
  moneyIcon: require('./moneyIcon.png'),
  keyIcon: require('./keyIcon.png'),
  leaderboardHeader: require('./leaderboardHeader.png'),
  leaderboardHeaderEmpty: require('./leaderboardHeaderEmpty.png'),
  jpegIcon: require('./jpegIcon.png'),
  recycleIcon: require('./recycleIcon.png'),
  requestResetThumbnail: require('./requestResetThumbnail.png'),
  resetPassSuccessIcon: require('./resetPassSuccessIcon.png'),
  resetPassThumbnail: require('./resetPassThumbnail.png'),
  saveIcon: require('./saveIcon.png'),
  searchDocumentIcon: require('./searchDocumentIcon.png'),
  taglineReko: require('./taglineReko.png'),
  taglineWhite: require('./taglineWhite.png'),
  threeStarsIcon: require('./threeStarsIcon.png'),
  transactionIcon: require('./transactionIcon.png'),
  trashIcon: require('./trashIcon.png'),
  truckIcon: require('./truckIcon.png'),
  userIcon: require('./userIcon.png'),
  weightIcon: require('./weightIcon.png'),
  xlsIcon: require('./xlsIcon.png'),
  plasticCredit: require('./plasticCredit.png'),
  miniRekoLogo: require('./miniRekoLogo.png'),
  miniRekoLogoPrimary: require('./miniRekoLogoPrimary.png'),
  targetIcon: require('./target.png'),
  emptyChartIcon: require('./emptyChartIcon.png'),
  emptyDefaultIcon: require('./emptyDefaultIcon.png'),
  emptyIcon: require('./emptyIcon.png'),
  icon: {
    fileDescription: require('./icon/fileDescription.png'),
    listCheck: require('./icon/listCheck.png'),
    map: require('./icon/map.png'),
    mapPin: require('./icon/mapPin.png'),
    truckReturn: require('./icon/truckReturn.png'),
    users: require('./icon/users.png'),
  },
};
