import { logger } from '../utils/logger';
import * as Sentry from '@sentry/react';

/**
 * Logger
 */
const log = logger().child({ module: 'ApiMonitor' });

export function responseMonitor(response: any) {
  log.info('🕵️ Api Response Monitor 🕵️');
  log.debug('Response :', response);

  const { status } = response;

  if (status !== 200) {
    Sentry.withScope(function (scope) {
      scope.setLevel('error');
      Sentry.captureException(
        new Error('Failed Api Call: ' + JSON.stringify(response.data)),
      );
    });

    if (status === 401) {
      log.debug('Token Expired ❌ ' + response.data.message);
      window.location.assign('/');
    }
  }
}
