import React from 'react';
import { classNames } from '../../../utils/classNames';
import { CARBON_FOOTPRINT_DECIMAL_NUM } from '../../../config/global.config';
import { CarbonFootprintType } from '../../../types/Dashboard';

interface Props {
  isOr: boolean;
  data: CarbonFootprintType;
  amount: number;
}

export const CarbonFootprintItem = (props: Props) => {
  return (
    <>
      {props.isOr ? (
        <span className="font-medium text-sm text-neutral-90">Or</span>
      ) : null}
      <div
        className={classNames(
          'flex flex-col px-4 py-5 mb-4 border-[1px] border-neutral-50 rounded-xl w-[17%] min-w-[15%] min-h-[196px]',
          props.data.background,
        )}
      >
        <span className="mb-1 font-medium text-3xl text-neutral-90">
          {props.amount.toFixed(CARBON_FOOTPRINT_DECIMAL_NUM)}
        </span>
        <span className="font-semibold text-sm text-neutral-80">
          {props.data.highlight}
          <span className="font-normal text-sm text-neutral-80">
            {props.data.title}
          </span>
        </span>
      </div>
    </>
  );
};
