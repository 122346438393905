import { ModalInfoType, ConfirmationModalType } from 'types';

export enum Constants {
  APP_NAME = 'Reko Dashboard',
}

export const wasteTypeDropdownTitle: string = 'Select waste type';

export const lineChartXTitleMonth: string = 'Pick up month';
export const lineChartXTitleWeek: string = 'Pick up week';
export const lineChartXTitleDates: string = 'Pick up dates';
export const lineChartYTitle: string = 'Weight per Pick up (Kg)';

export const verticalChartXTitle: string = 'Type of Waste';
export const verticalChartYTitle: string = 'Weight of Waste (Kg)';
export const verticalChartYTitlePcs: string = 'Weight of Waste (Pcs)';

export const pieChartTitle: string = 'Waste Percentage';

export const lineChartXTitlePeriodMonth: string = 'Period (Months)';
export const lineChartXTitlePeriodWeek: string = 'Period (Weeks)';
export const lineChartXTitlePeriodDates: string = 'Period (Days)';

export const lineChartYTitleTotalDropIn: string = 'Total of Drop-in (times)';

export const lineChartYTitleUsersDropIn: string = 'Total of Users';

export const kilogramUnits: string = 'Kg';
export const timesUnits: string = 'Times';
export const usersUnits: string = 'Users';
export const pcsUnits: string = 'Pcs';
export const transactionUnits: string = 'Transaction';

export const carbonFootprintTitle: string =
  'Your participation matters! From the waste you have dropped this month, you have saved:';
export const carbonSavings: string = 'Total carbon saving';
export const co2Emission: string = 'CO₂ emission';
export const co2EmissionEquals: string =
  "The CO2 emissions you've saved are equal to:";

export const BUSINESS_TYPE_COMPILE: string = 'Pickup Business - All';

export const DROP_IN_TYPE_COMPILE: string = 'Droppoint - All';

export const REBOX_TYPE_COMPILE: string = 'Reko Box - All';

export const BRANDBOX_TYPE_COMPILE: string = 'Brandbox - All';

export const XLSX_TYPE: string = 'xlsx';
export const CSV_TYPE: string = 'csv';
export const PDF_TYPE: string = 'pdf';

export const AMOUNT_WASTE_PCS: string = 'Amount of Waste (Pcs)';
export const AMOUNT_OF_TRANSACTION: string = 'Amount of Transactions';
export const AMOUNT_OF_USER: string = 'Amount of Users';

export const SANKEY_LEGEND = [
  'Source',
  'Collection',
  'Segregation',
  'Distribution',
];

export enum Y_PARAM_CONVERTER_TYPE {
  Weight,
  Drop,
  User,
  Transaction,
  Waste,
}

export const DROP_IN_TOTAL_WEIGHT_FILENAME = 'Drop-In Total Weight-';
export const DROP_IN_TOTAL_WASTE_FILENAME = 'Drop-In Total Waste-';
export const DROP_IN_TOTAL_USER_FILENAME = 'Drop-In Total User-';
export const DROP_IN_WEIGHT_PER_TYPE_FILENAME = 'Drop-In Weight Per Type-';
export const DROP_IN_WASTE_JOURNEY_FILENAME = 'Drop-In Waste Journey-';

export const REBOX_BRANDBOX_TOTAL_WASTE_FILENAME =
  'Rebox Brandbox Total Waste-';
export const REBOX_BRANDBOX_WEIGHT_PER_TYPE_FILENAME =
  'Rebox Brandbox Weight Per Type-';
export const REBOX_BRANDBOX_TOTAL_TRANSACTION_FILENAME =
  'Rebox Brandbox Total Transaction-';
export const REBOX_BRANDBOX_TOTAL_USER_FILENAME = 'Rebox Brandbox Total User-';

export const BUSINESS_WASTE_JOURNEY_FILENAME =
  'Repickup Business Waste Journey-';
export const BUSINESS_WEIGHT_PER_PICKUP_FILENAME =
  'Repickup Business Weight Per Pickup-';
export const BUSINESS_WEIGHT_PER_TYPE_FILENAME =
  'Repickup Business Weight Per Type-';

export const ACTION_DOWNLOAD: string = 'download';
export const ACTION_PREVIEW: string = 'preview';
export const WEEK_TOOLTIP_TITLE: string = 'What is ISO Week ?';
export const WEEK_TOOLTIP_DESC: string =
  'We use the ISO week system to number weeks in a year according to ISO 8601. It begins with the first week of January and gives each week a number.';

// Login
export const FORGOT_PASSWORD: string = 'Forgot Password';
export const FORGOT_PASSWORD_RESET: string = 'Reset';
export const BACK_TO: string = 'Back to';
export const BACK_TO_LOGIN: string = 'Login';
export const REQUEST_RESET_PASSWORD_MODAL: ModalInfoType = {
  title: 'Recovery link sent!',
  desc: 'Check your email, we already sent link to validating email and create new password',
};
export const RESET_EMAIL_TITLE: string = 'Your email address';
export const RESET_PASSWORD_LABEL: string = 'New password';
export const RESET_PASSWORD_PLACEHOLDER: string = 'your new password';
export const RESET_CONF_PASSWORD_LABEL: string = 'Confirm new password';
export const RESET_CONF_PASSWORD_PLACEHOLDER: string = 'confirm new password';
export const RESET_PASSWORD_FOOTNOTE: string =
  'The password must have at least 8 character, contains numbers, and a combination of lower and uppercase';
export const RESET_CONF_PASSWORD_FOOTNOTE: string =
  'Sorry the password doesn’t match, please try again';
export const RESET_PASSWORD_SUCCESS_MODAL: ModalInfoType = {
  title: 'New password secured!',
  desc: 'Congrats! Your new password been created. Now before we start, login first with new password',
};

// Account manager
export const DELETE_ACCOUNT_CONFIRMATION_MODAL: ConfirmationModalType = {
  title: 'Delete Account',
  body: 'Once account deleted, the data will can’t restore to the list. Are you sure you want to delete ',
  positiveText: 'Yes, sure',
  negativeText: 'Cancel',
};
export const DELETE_ACCOUNT_CONFIRMATION_BODY: string =
  ' from the account list?';
export const SHARE_ACCESS_CONFIRMATION_MODAL: ConfirmationModalType = {
  title: 'Share Confirmation',
  body: 'Once you fill in or update access, be sure to share it to the right data or person. Do you want to share access now?',
  positiveText: 'Sure, share',
  negativeText: 'Cancel',
};
export const SHARE_ACCESS_SUCCESS_MODAL: ModalInfoType = {
  title: 'Share access success!',
  desc: 'Your access invitation have been sent to the person',
};
export const DELETE_ACCESS_INFO_MODAL: ModalInfoType = {
  title: 'Account Deleted!',
  desc: 'The account have been deleted from the list',
};
export const EDIT_ACCESS_INFO_MODAL: ModalInfoType = {
  title: 'Change Saved!',
  desc: 'Account have been updated and saved on the list',
};
export const EDIT_ACCESS_CONFIRMATION_MODAL: ConfirmationModalType = {
  title: 'Save Changes',
  body: 'You have made changes to this account. Are you sure you want to save the changes?',
  positiveText: 'Yes, sure',
  negativeText: 'Cancel',
};
