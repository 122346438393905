import React from 'react';
import { Spinner } from '../Spinner';
import { classNames } from '../../../utils/classNames';
import { DEFAULT_LOADING_TEXT } from '../../../config/global.config';

interface Props {
  isActive?: boolean;
  text?: string;
}

export const LoadingOverlay = ({
  isActive = false,
  text = DEFAULT_LOADING_TEXT,
}: Props) => {
  const opacityValue = isActive
    ? 'opacity-100'
    : 'opacity-0 pointer-events-none';

  return (
    <div
      id="loading-screen"
      className={classNames(
        opacityValue,
        'w-full h-full fixed block top-0 left-0 z-50 flex flex-col justify-center items-center transition transition-opacity ease-in-out delay-150',
      )}
      style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}
    >
      <Spinner size={'large'} className={'relative mb-4 opacity-100'} />
      <h2 className="mt-6 text-2xl font-extrabold text-gray-200">{text}</h2>
    </div>
  );
};
